import React, { Component } from 'react';
import InstaLink from './InstaLink.js';
import https from 'https';


class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.requiredFields = [
      'given-name',
      'family-name',
      'email',
      'subject',
      'message'
    ];
    this.emailRegex = /\w+@\w+\.[a-z]{2,}/i;
    this.fieldsRequiredMessage = 'All fields with an asterisk (*) are required.';
    this.correctEmailRequiredMessage = 'A valid email is required.';
    this.errorSendingMessage = 'Sorry, something went wrong. Please try again.';
    this.state = {
      fieldsRequiredMessage: this.fieldsRequiredMessage,
      fieldsRequiredClass: '',
      formClass: '',
      successMessageClass: '',
      submitButtonClass: 'primary',
      sendButtonValue: 'Send',
      messageUsText: 'To learn more about guru.club, or to inquire about a case study of your own, leave us a message!'
    };
  }

  render() {
    return (
      <div className="contact-us-wrapper">
        <div className="contact-us">
          <div className="contact-us-row">
            <div className="follow-us">
              <p className="contact-us-title">Follow Us</p>
              <div className="follow-us-links">
                <InstaLink darkMode={true} style={{width: '45px'}}/>
              </div>
            </div>
            <div className="find-us">
              <p className="contact-us-title">Find Us</p>
              <iframe title="map" id="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.6887560805862!2d-71.05199598432931!3d42.34915874378237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a7fdbd56c47%3A0x104d6cdc3d42531a!2s51+Melcher+St%2C+Boston%2C+MA+02210!5e0!3m2!1sen!2sus!4v1539807893665" style={{border:0, frameBorder: 0, height: 300, width: 400}} />
              <p id="google-map-caption">We operate out of Boston, MA!</p>
            </div>
          </div>
          <div className="contact-us-row">
            <div className="message-us">
              <p className="contact-us-title" id="case-study">Message Us</p>
              <p id="success-message" className={this.state.successMessageClass}>{this.state.messageUsText}</p>
              <form id="message-us-form" className={this.state.formClass} autoComplete="on" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  <div className="form-field name-field">
                    <label htmlFor="given-name">First Name *</label>
                    <input id="given-name" x-autocompletetype="given-name" type="text" name="given-name" onChange={this.handleInputChange}/>
                  </div>
                  <div className="form-field name-field">
                    <label htmlFor="family-name">Last Name *</label>
                    <input id="family-name" x-autocompletetype="family-name" type="text" name="family-name" onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className="form-field">
                  <label htmlFor="company">Company</label>
                  <input id="company" x-autocompletetype="company" type="text" name="company" onChange={this.handleInputChange}/>
                </div>
                <div className="form-field">
                  <label htmlFor="email">Email *</label>
                  <input id="email" x-autocompletetype="email" type="text" name="email" onChange={this.handleInputChange}/>
                </div>
                <div className="form-field">
                  <label htmlFor="subject">Subject *</label>
                  <input id="subject" type="text" name="subject" onChange={this.handleInputChange}/>
                </div>
                <div className="form-field">
                  <label htmlFor="message">Message *</label>
                  <textarea id="message" name="message" onChange={this.handleInputChange}/>
                </div>
                <p id="fields-required" className={this.state.fieldsRequiredClass}>{this.state.fieldsRequiredMessage}</p>
                <input type="submit" className={this.state.submitButtonClass} value={this.state.sendButtonValue} onChange={this.handleInputChange}/>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    // check if the error message is showing
    if (this.state.fieldsRequiredClass === 'show') {
      // Create a flag to hide the error message, default is false
      var hideRequiredMessage = false;

      // Check if the error message is fieldsRequiredMessage
      if (this.state.fieldsRequiredMessage === this.fieldsRequiredMessage) {
        // hide message if the current field is required and being filled
        hideRequiredMessage = this.requiredFields.includes(name) && (!this.state[name] || this.state[name].length < 1)
      } else {
        // If we're in here, the error is an invalid email
        hideRequiredMessage = (name === 'email' && this.emailRegex.test(value))
      }

      if (hideRequiredMessage) {
        // Let them know they're on the right track.
        this.setState({fieldsRequiredClass: ''});
      }
    }
    this.setState({[name]: value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.submitButtonClass !== 'primary') {
      return;
    }
    for (var i in this.requiredFields) {
      let requiredField = this.requiredFields[i];
      if (!this.state[requiredField] || this.state[requiredField].length < 1) {
        this.setState({fieldsRequiredMessage: this.fieldsRequiredMessage, fieldsRequiredClass: 'show'});
        return;
      }
    }
    if (!this.emailRegex.test(this.state['email'])) {
      this.setState({fieldsRequiredMessage: this.correctEmailRequiredMessage, fieldsRequiredClass: 'show'});
      return;
    }

    this.setState({fieldsRequiredClass: ''});
    var options = {
      host: "8qhscmink4.execute-api.us-east-1.amazonaws.com/master",
      path: "/contactus",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    }
    let req = https.request(options, this.handleSubmitRequest);
    this.setState({submitButtonClass: 'loading primary', sendButtonValue: 'Sending'});
    let body = JSON.stringify({company: this.state['company'], from: this.state['email'], subject: this.state['subject'], message: this.state['message'], firstName: this.state['given-name'], lastName: this.state['family-name']});
    req.on("error", this.messageSendError)
    req.write(body);
    req.end();
  }

  handleSubmitRequest = (res) => {
    //var responseString = "";
    res.on("data", function (data) {
      //responseString += data;
    });
    res.on("end", this.messageSent);
  }

  messageSendError = (error) => {
    console.log(error);
    this.setState({fieldsRequiredMessage: this.errorSendingMessage, fieldsRequiredClass: 'show', submitButtonClass: 'primary', sendButtonValue: 'Send'});
  }

  messageSent = () => {
    this.setState({formClass: 'hidden', messageUsText: 'Your message has been sent. We will get back to you soon!'});
  }
}

export default ContactUs;
