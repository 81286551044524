import React, { Component } from 'react';

const lightInstagram = {
  inactive: require('./Images/icons8-instagram-50.png'),
  active: require('./Images/icons8-instagram-filled-50.png')
};

const darkInstagram = {
  inactive: require('./Images/icons8-instagram-150.png'),
  active: require('./Images/icons8-instagram-filled-150.png')
};

class InstaLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: 'inactive',
    };
  }

  render() {
    const { mode } = this.state;
    var src = this.props.darkMode ? darkInstagram[mode] : lightInstagram[mode];
    return (
      <a href="https://www.instagram.com/shopguruclub" onMouseEnter={this.instagramLinkHover} onMouseLeave={this.instagramLinkLeave}>
        <img style={this.props.style} alt={"Open Instagram"} src={src} />
      </a>
    )
  }

  instagramLinkHover = () => {
    this.setState({mode: 'active'});
  }

  instagramLinkLeave = () => {
    this.setState({mode: 'inactive'});
  }
}

export default InstaLink;
