import React, { Component } from 'react';

class EmbeddedInstagram extends Component {
  constructor(props) {
    super(props);
    var embedClass = "embedded-instagram";
    if (props.rotate) {
      embedClass += " rotated"
    }
    this.state = {
      embedClass: embedClass
    };
  }

  render() {
    return (<div className={this.state.embedClass} id={this.props.id}>{this.props.embedHtml}</div>);
  }

  componentDidMount() {
    if (!(window.instgrm || document.getElementById('instagram-embed-script'))) {
      this.injectScript();
    }
    this.checkForScript().then(() => {
      var { embedClass } = this.state;
      embedClass += " rendered";
      this.setState({embedClass: embedClass});
      window.instgrm.Embeds.process();
    });
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  injectScript(): void {
    const s = document.createElement('script');
    s.async = s.defer = true;
    s.src = 'https://www.instagram.com/embed.js';
    s.id = 'instagram-embed-script';
    const body: HTMLElement | null = document.body
    if (body) {
      body.appendChild(s);
    }
  }

  checkForScript(): Promise<any> {
    return new Promise(resolve => {
      (function checkForScript(_this) {
        _this._timer = setTimeout(() => {
          if (window.instgrm) {
            clearTimeout(_this._timer);
            resolve();
          } else {
            checkForScript(_this);
          }
        }, 20);
      })(this)
    });
  }
}

export default EmbeddedInstagram;
