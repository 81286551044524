import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EmbeddedInstagram from './EmbeddedInstagram.js';
import firstRowData from './data/firstRowData.js';
const logo = require('./Images/Guru_Logo_Circle.png');
const AppScreen = require('./Images/AppScreen.png');


const maxColumnCount = 6;
const maxRowCount = 8;
//const stdBoxLength = 93.75;
const minContainerHeight = 516;
const minContainerWidth = 375;
const maxContainerWidth = 1200;
const maxContainerHeight = 700;


class Portal extends React.Component {

  constructor(props) {
    super(props);
    var gridStyle = {maxHeight: null};
    var linkStyle;
    this.stdBoxLength = Math.min(
      (maxContainerWidth / 2) / maxColumnCount, maxContainerHeight / maxRowCount
    );
    this.minColumnCount = Math.ceil(minContainerWidth / this.stdBoxLength);
    this.minRowCount = Math.ceil(minContainerHeight / this.stdBoxLength);
   this.minGridHeight = this.stdBoxLength * this.minRowCount;
   this.maxGridWidth = this.stdBoxLength * maxColumnCount;
   this.maxGridHeight = this.stdBoxLength * maxRowCount;
    this.state = {
      style: {gridStyle: gridStyle,
      linkStyle: linkStyle},
      images: []
    }
  }

  componentWillMount() {
    const { innerWidth, innerHeight } = window;
    this.populateGrid();
    this.calculateGridSizes(innerWidth, innerHeight);
    setInterval(this.setRandomImage, 1600)
  }

  populateGrid = () => {
    // Populate the entire grid with random images from firstRowData
    // (only the index for firstRowData is stored)
    var images = [];

    for (var i = 0; i < maxColumnCount * maxRowCount; i ++) {
      var nextImg;
      while (!nextImg || images.includes(nextImg)) {
        nextImg = Math.floor(Math.random() * firstRowData.length);
      }
      images.push(nextImg);
    }
    this.setState({images: images});
  }

  setRandomImage = () => {
    if (this.state.hideGrid === false) {
      // currently visible # of rows & columns
      const { columnCount, rowCount } = this.state;
      var { images } = this.state;

      // Grab a square within the bounds of what's currently visible
      const nextBubble = Math.floor(Math.random() * columnCount * rowCount);

      // Accept first image not already in entire grid (including not visible squares)
      var nextImg;
      while (!nextImg || images.includes(nextImg)) {
        nextImg = Math.floor(Math.random() * firstRowData.length);
      }
      images[nextBubble] = nextImg;
      this.setState({images: images});
    }
  }

  componentDidUpdate(prevProps) {
    const { innerWidth, innerHeight } = this.props;
    if ((innerWidth !== prevProps.innerWidth || innerHeight !== prevProps.innerHeight) && !this.throttling) {
      this.resetTimer(innerWidth, innerHeight);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.throttleTimeout);
    this.throttling = false;
  }

  resetTimer = (width, height) => {
    this.throttling = true;
    clearTimeout(this.throttleTimeout);
    this.throttleTimeout = setTimeout(() => {
      const { innerWidth: newWidth, innerHeight: newHeight } = this.props;
      this.calculateGridSizes(newWidth, newHeight);
      if (newWidth !== width || newHeight !== height) {
        this.resetTimer(newWidth, newHeight);
      } else {
        this.throttling = false;
      }
    }, 100);
  }

  calculateGridSizes = (width, height) => {
    // grid and menu must be at least 375 wide each and share space
    if (width < minContainerWidth * 2) {
      if (!this.state.hideGrid) {
        this.setState({hideGrid: true, containerHeight: 'calc(100% - 72px)', containerWidth: '100%'});
      }
      return;
    }

    const gridWidth =  Math.min((width / 2.0), this.maxGridWidth);
    const canFitHorizontally = Math.floor(gridWidth / this.stdBoxLength);
    const columnCount = Math.max(Math.min(canFitHorizontally, maxColumnCount), this.minColumnCount);


    const gridHeight = Math.max(Math.min(this.maxGridHeight, height - 72), this.minGridHeight); // total height minus top bar
    const canFitVertically = Math.floor(gridHeight / this.stdBoxLength);
    const rowCount = Math.max(Math.min(canFitVertically, maxRowCount), this.minRowCount);

    const needsToGrow = Math.min((gridWidth % this.stdBoxLength) / columnCount, (gridHeight % this.stdBoxLength) / rowCount);
    const boxLength = this.stdBoxLength + needsToGrow;

    this.setState({
      containerWidth: boxLength * columnCount * 2,
      containerHeight: boxLength * rowCount,
      columnCount: columnCount,
      rowCount: rowCount,
      boxLength: `${boxLength - 5}px`,
      hideGrid: false
    });
  }

  render() {
    var portalGrid;
    var menuWidth;

    if (this.state.hideGrid === false) {
      menuWidth = '50%';
      var squares = [];
      const { columnCount, rowCount } = this.state;
      const maxSquareHeight = `${100 / rowCount}%`;
      const maxSquareWidth = `${100 / columnCount}%`;
      for (var i = 0; i < columnCount * rowCount; i ++) {
        squares[i] = <GridSquare maxHeight={maxSquareHeight} maxWidth={maxSquareWidth} boxLength={this.state.boxLength} key={i} data={firstRowData[this.state.images[i]]} />
      }
      portalGrid = (
        <div id="portal-grid" className={`appear row flex-wrap flex-start align-start`} style={{width: '50%'}}>
          {squares}
        </div>
      );
    } else {
      menuWidth = '100%';
      portalGrid = null;
    }
    return (
      <div className="room-up-top dark stretch-width">
        <div className="room-up-top dark column centered align-center stretch-width" style={{minHeight: 'calc(100% - 72px)', marginBottom: "50px"}}>
            <div className="row centered " style={{width: `${this.state.containerWidth}px`, height: `${this.state.containerHeight}px`, minHeight: `${this.minContainerHeight}px`}}>
              <div className="column centered align-center" style={{minWidth: '375px', width: menuWidth}}>
                <div className="portal-title">
                  <img alt={""} src={logo} style={{width: '75px'}}/>
                  guru.club
                </div>
                <div id="portal-hook" className="column space-between align-center" style={{height: '100px'}}>
                  <h2>The Future of Shopping</h2>
                  <div className="row space-around align-center" style={{width: '300px'}}>
                    <Link className="link-button gold" to='/app/brand'>I am a brand.</Link>
                    <Link className="link-button blue" to='/app/shopper'>I am a shopper.</Link>
                  </div>
                </div>
                <img id="AppScreen" src={AppScreen} />
              </div>
              {portalGrid}
            </div>
            <div style={{marginTop: '50px'}} className="row align-center">
              <a style={{marginRight: '25px'}} href="https://www.bostonherald.com/2019/07/15/new-guru-club-app-aims-to-connect-instagram-users-to-brands/">As seen in </a>
              <a href="https://www.bostonherald.com/2019/07/15/new-guru-club-app-aims-to-connect-instagram-users-to-brands/" ><img src={require('./Images/2880px-Boston-herald.svg.png')}/></a>
            </div>
        </div>
        <div className="white stretch-width column align-center centered">
          <div style={{"maxWidth": "1200px", margin: '20px 60px 20px 60px'}} className="row centered flex-wrap align-center">
            <div className="stretch-width row centered">
              <a key={"VirginAtlantic.png"} href={"https://www.virginatlantic.com"}><img style={{height: '50px'}} src={"https://conduit-dev-website.s3.amazonaws.com/VirginAtlantic.png"} className="brand-logo"/></a>
            </div>
            { brandLogos.map(l => <a key={l.img} href={l.link}><img style={{height: l.height}} src={"https://conduit-dev-website.s3.amazonaws.com/" + l.img} className="brand-logo" /></a>) }
          </div>
        </div>
      </div>
    );
  }
}

const brandLogos = [
  {
    link: "https://luxe-leisure.com/",
    height: "75px",
    img: "LuxeLeisure.png"
  },
  {
    link: "https://theitough.com/",
    height: "56px",
    img: "itough.jpg"
  },
  {
    link: "https://www.islideusa.com/",
    height: "41px",
    img: "islide.PNG"
  },
  {
    link: "https://www.shinyleaf.com/",
    height: "155px",
    img: "ShinyLeaf.png"
  },
  {
    link: "https://www.boldbodyapparel.com/",
    height: "45px",
    img: "boldbody.PNG"
  },
  {
    link: "https://www.hilogummies.com/",
    height: "75px",
    img: "hilo.png"
  },
  {
    link: "https://tryautobrush.com/",
    height: "50px",
    img: "autobrush.png"
  },
  {
    link: "https://nanobotanica.com/",
    height: "65px",
    img: "nano.png"
  },
  {
    link: "https://www.trysnow.com/",
    height: "65px",
    img: "snow.jpg"
  },
  {
    link: "https://www.iceshaker.com/",
    height: "65px",
    img: "IceShaker.png"
  },
  {
    link: "https://www.theproteincookiecompany.com/",
    height: "72px",
    img: "ProteinCookie.jpg"
  },
  {
    link: "https://shopheychick.com/",
    height: "62px",
    img: "hey_chick.png"
  },
  {
    link: "https://3deckerpomade.com/",
    height: "50px",
    img: "3DeckerPomade.png"
  },
  {
    link: "https://shopcattivo.com/",
    height: "38px",
    img: "Cattivo.png"
  },
  {
    link: "https://bleusalt.com/",
    height: "85px",
    img: "Bleusalt_finalLOGO-websafe_navy_200x.png"
  },
  {
    link: "https://www.bostonseaport.xyz/",
    height: "22px",
    img: "seaport.png"
  },
  {
    link: "https://kinibands.com/",
    height: "72px",
    img: "KiniBands.jpg"
  },
  {
    link: "https://www.bexsunglasses.com/",
    height: "72px",
    img: "Bex.png"
  },
  {
    link: "https://www.mirrex.store/",
    height: "52px",
    img: "Mirrex.png"
  },
  {
    link: "https://www.backbayroasters.com/",
    height: "90px",
    img: "BackBayRoasters.png"
  },
  {
    link: "https://www.lovehandle.com/",
    height: "60px",
    img: "lovehandle.jpg"
  },
  {
    link: "https://wickedprotein.com/",
    height: "50px",
    img: "WickedProtein.png"
  },
  {
    link: "https://atlasbars.com/",
    height: "60px",
    img: "Atlas.jpg"
  },
  {
    link: "https://drinklococoffee.com/",
    height: "50px",
    img: "Loco.png"
  },
  {
    link: "https://fitlyrun.com",
    height: "62px",
    img: "Fitly.jpg"
  },
  {
    link: "https://safe-t.us/",
    height: "73px",
    img: "SafeT.jpg"
  },
  {
    link: "https://oceanandcompany.com/",
    height: "73px",
    img: "o&co.jpg"
  },
  {
    link: "https://proteinpowerball.com/",
    height: "78px",
    img: "proteinpowerball.png"
  },
  {
    link: "https://www.lacedandlavish.com/",
    height: "73px",
    img: "laced&lavish.jpg"
  },
  {
    link: "https://www.colosseinglasses.com/",
    height: "73px",
    img: "colossein.png"
  },
  {
    link: "https://malletandmandrel.com/",
    height: "113px",
    img: "mallet&mandrel.jpg"
  },
  {
    link: "https://www.lalasheboutique.com/",
    height: "93px",
    img: "lalashe.jpg"
  },
  {
    link: "https://www.nectarsunglasses.com/",
    height: "48px",
    img: "nectar.png"
  },
  {
    link: "https://soudasouda.com/",
    height: "48px",
    img: "souda.jpg"
  },
  {
    link: "https://royaleboston.com/",
    height: "78px",
    img: "royale.jpg"
  },
  {
    link: "https://www.causewayboston.com/",
    height: "48px",
    img: "causeway.jpg"
  },
  {
    link: "https://www.4thwallrestaurant.com/",
    height: "48px",
    img: "4thwall.png"
  }]

class GridSquare extends Component {
  state = {className: 'hidden'}

  componentWillMount() {
    this.setState({src: this.props.data.src});
  }

  hide = () => {
    clearTimeout(this.hideTimeout);
    this.setState({className: 'hidden'});
    this.hideTimeout = setTimeout(() => {
      this.setState({src: this.props.data.src});
    }, 800)
  }

  show = () => {
    this.setState({className: ''});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.src !== this.props.data.src) {
      this.hide();
    }
  }

  render() {
    return (
      <a style={{maxWidth:this.props.maxWidth, maxHeight: this.props.maxHeight}} className={`grid-link ${this.state.className}`} href={this.props.data.link}>
        <img alt="" onLoad={e => this.show()} style={{width: this.props.boxLength, height: this.props.boxLength, objectFit: 'cover', maxWidth:'100%',maxHeight:'100%'}} src={`https://dt8pdwz1r5mq4.cloudfront.net/grid/${this.state.src}`} />
      </a>
    )
  }
}

export default Portal;
