import React, { Component } from 'react';
import './About.css';
import team from './data/team.json';

class About extends Component {

  render() {
    return (
      <div>
        <div className="about-intro">
          <h1 style={{textAlign: 'center'}}>Welcome to the club.</h1>
        </div>
        <div className="about">
          <div className="about-div" id="our-mission" style={{backgroundColor: '#EDEDF5'}}>
          <h1>A New Way to Shop</h1>
          <h2>guru.club is a members-only marketplace that allows pre-approved shoppers to buy products and post photos and videos of them in exchange for cash back rebates and store credit. Brands receive authentic word-of-mouth advertising, valuable feedback from customers (first-party data) and user-generated content at scale for a fraction of their current marketing costs.</h2>
          <div className="blue-bubble row centered flex-wrap" style={{width: '100%'}} >
            <div className="split-box split-box-left" id="brand-faq">
              <h4 style={{marginBlockEnd: '0.5em', marginBlockStart: '1em'}}>Brand FAQ</h4>
              <h6 style={{marginBlockEnd: '0.5em'}}>What are the requirements to be accepted as a brand on the guru.club marketplace?</h6>
              <p>guru.club approves brands based on several factors. We look for new, up and coming companies with exceptional branding and traction.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>How does it work as a brand?</h6>
              <p>guru.club operates as a sales channel on Shopify. Customers, or gurus as they are called, purchase a product on the marketplace at full retail price. When they post a photo or video on Instagram tagging your brand with the product visible, guru.club automatically confirms the post and compensates them with an immediate rebate from you that is deposited into their account. You can also give an additional store credit tip (exclusive to your brand) to a guru shopper for a post that is exemplary.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>How do I get started?</h6>
              <p><a style={{color: 'black'}} href={"https://apps.shopify.com/guru-club"}>Add us as an app on the Shopify Sales Channel page.</a></p>
              <h6 style={{marginBlockEnd: '0.5em'}}>How much does it cost to get started?</h6>
              <p>Nothing! guru.club turns your marketing program from a cost center to a profit center. You pay no introductory costs or monthly fees and are only charged a 5% transaction fee after a guru review is posted. All you need is a great product and inventory to get started.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>How do I apply/create an Account?</h6>
              <p>Your account is created after you hit Connect on the Account page and then fill out the form for creating an account. After completing the form, hitting Apply will close the form and bring you back to the Account page. If the Account page still says Connect, you may need to reload the page. Once accepted, publish your store.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>How do I list my products?</h6>
              <p>Listing products is done in your Shopify Admin Products page, outside of the guru.club sales channel. Select the correct products, then under Actions choose Make products available. Check off guru.club.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>How much of a rebate should I give?</h6>
              <p>It’s up to you. The higher the rebate, the more likely you will get a purchase/post from guru shoppers. The suggested rebate is 50% of the full retail price.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>What is the average rebate?</h6>
              <p>By default, any product you list will be discounted 50%. To edit any product’s discount, head to the Products page in the guru.club sales channel. There you may edit the final price of the product to a guru.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>Where can I view and download my content?</h6>
              <p>In addition to Instagram, your content will populate the Sales page in the sales channel as they are created. To receive cashback, we require users to post within 30 days of purchasing the item. From there, you will be able to view and download content for repurpose. You do not need to ask for additional permission from creators to reuse guru.club content.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>What is a tip and how do I leave one?</h6>
              <p>For recently completed posts, you will have the option to tip your customer. Tipping grants in-store credit to your store within the guru.club app. The customer will be discounted the amount that you tip on a future purchase.</p>
              <h6 style={{marginBlockEnd: '0.5em'}}>What is the return policy?</h6>
              <p>guru.club follows your return policy. Your package should include your return policy. We suggest you choose not to accept returns after a rebate has already been complete.</p>
          </div>
          <div className="split-box" id="shopper-faq">
            <h4 style={{marginBlockEnd: '0.5em', marginBlockStart: '1em'}}>Shopper FAQ</h4>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              What is the requirement to be accepted as a guru shopper?
            </h6>
              <p>guru.club approves guru Instagram accounts through a carefully developed verification algorithm which takes into account a number of statistical indicators - including activity, number of followers, and engagement rate over time. guru accounts must be set to public.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              How do I earn cash back?
            </h6>
              <p>Cashback is earned by posting an Instagram story or post that tags and @mentions the brand&apos;s Instagram account within 30 days of purchasing an item. For Instagram stories you do not need tag, only @mention the brand.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              Can I tag more than 1 brand?
            </h6>
              <p>A separate posting is required for each item that is purchased on guru.club with that product visible in the post. DO NOT tag more than one brand.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              Should I tag @shopguruclub in my posts and stories?
            </h6>
              <p>DO NOT tag or mention @ShopGuruClub in your branded post.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              How long do I need to leave up my posts?
            </h6>
              <p>Stories must be left up for 24 hours and posts for at least 3 days.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              Where does my cashback go?
            </h6>
              <p>Cashback is deposited into your guru.club account once a deal is completed within the allotted time. Money can be transferred back into your bank account from which you used to buy the item for a 2.9% fee. Bank transfers take up to 14 days.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              What will the charge look like on my card?
            </h6>
              <p>Your payment method will receive a charge from the merchant for each product you purchase, plus one for the cashback withholding.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              What is a tip?
            </h6>
              <p>A tip is given when a brand loves a post and wants to reward the shopper with extra store credit to continue to repeat the process. Tips can only be used back to the guru.club stores they were issued from. They cannot be transferred into your bank account.</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              How do I earn a tip?
            </h6>
              <p>Creativity, engagement with your post or story, and positive reviews about the product you are posting about. Posts generally earn better tips than stories. (Tips are completely optional from the brands).</p>
            <h6 style={{marginBlockEnd: '0.5em'}}>
              What is the return policy?
            </h6>
              <p>guru.club follows the retailer&apos;s return policy. Your package should include the retailer&apos;s return policy, and you&apos;ll need to return items via mail, just like when you purchase directly from that retailer. You may not return an item after you have already earned cashback on that item.</p>
            <h6>
              Do I have to put #Ad in my post?
            </h6>
            <p>
              The FTC’s Endorsement Guides provide that if there is a “material connection” between an endorser and an advertiser – in other words, a connection that might affect the weight or credibility that consumers give the endorsement – that connection should be clearly and conspicuously disclosed, unless it is already clear from the context of the communication.
            </p>
          </div>
        </div>
      </div>
            <div className="about-div" id="our-team">
            <h1>our team</h1>
            <div className="team-display">
              {team.map(member => (<TeamCard key={member.bucket_key} bucket_key={member.bucket_key} name={member.name} role={member.role} />))}
            </div>
            </div>
        </div>
      </div>
      );
  }
}

class TeamCard extends Component {

  render() {
    return (
      <div className="team-card">
        <img alt="" src={`https://dt8pdwz1r5mq4.cloudfront.net/${this.props.bucket_key}`} />
        <div>
          <h3 style={{margin: '0px 0px 0px 0px'}}>{this.props.name}</h3>
          <h4 style={{margin: '0px 0px 0px 0px', textAlign: 'center', color: 'rgba(0,0,0,0.60)', fontSize: '16px'}}>{this.props.role}</h4>
        </div>
      </div>
    )
  }
}

export default About;
