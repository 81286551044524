// DealListItem.js

import React from 'react';
import {
  ResourceList,
  Thumbnail,
  Avatar,
  Select,
  Button
} from '@shopify/polaris';
import './DealListItem.css';

const profilePictureBucket = "https://dr2ucvwr1tnfi.cloudfront.net/";
const contentBucket = "https://dl0dpcradwvbv.cloudfront.net/";

function DealListItem(props) {

  const {
    _id,
    status,
    expires,
    closed,
    content,
    influencer,
    instagramPost,
    tipsEnabled,
    tip: finalTip,
    manual_tip,
    price,
    productName
   } = props;

  var media;
  var statusText;
  var likes;
  var tipDiv;
  var shortcutActions = [];
  let downloadUrl;
  if (status === "Done") {
    // if deal is done, use the content as item media
    if (content && content.length) {
      const toDisplay = content[content.length - 1];
      if (toDisplay.key) {
        downloadUrl = contentBucket + toDisplay.key;
        const thumbnail_url = contentBucket + toDisplay.thumbnailKey;
        media = (
          <Thumbnail
            source={thumbnail_url}
            alt=""
            size="large"
          />
        );
        
      }
    }
    if (instagramPost) {
      const { like_count, permalink } = instagramPost;
      likes = like_count;
      if (permalink) {
        shortcutActions.push({
          content: "View Post",
          url: permalink,
          external: true
        });
      }
    }
    statusText     = "Completed";
    statusText    += (closed ? ` on ${new Date(closed).toDateString()}.` : '.');

    let tip = (tipsEnabled ? manual_tip : finalTip) || 0;
    if (tipsEnabled && price) {

      const getRoundDollar = function(percentage) {
        const amount = Math.floor(Math.round(percentage * price / 100.0) * 100);
        percentage = Math.round(amount / price * 100);
        return {
          label: `${percentage}% (${penniesToString(amount)})`,
          value: amount.toString()
        };
      }

      tipDiv = (
        <Select
          label="Tip"
          labelInline
          options={[
            {label: "None", value: "0"},
            ...([0.2, 0.33, 0.5].map(getRoundDollar))
          ]}
          value={tip.toString()}
        />
      );
    } else if (tip) {
      tipDiv = `${penniesToString(tip)} tip`;
    }

  } else {
    // if deal is expired/pending, use user avatar as item media
    // if (influencer && influencer._id) { // unnecessary validation
      const initials = influencer.firstName && influencer.lastName &&
        influencer.firstName.charAt(0) + influencer.lastName.charAt(0);
      let name;
      if (influencer.firstName && influencer.lastName) {
        name = influencer.firstName + " " + influencer.lastName;
      } else {
        name = influencer.igHandle;
      }
      media = (
        <Avatar
          source={profilePictureBucket + influencer._id}
          alt={"Deal with " + name}
          initials={initials}
          customer={true}
          name={name}
          size="large"
        />
      );
    // }
    if (status === "Expired") {
      statusText  = "Expired";
      statusText += (expires ? ` on ${new Date(expires).toDateString()}.` : '.');
    } else {
      statusText  = "Waiting for user's post.";
    }
  }

  likes = (likes && likes + " likes");

  const nameText = influencer.igHandle && `@${influencer.igHandle}`;
  
  // why a div wrapping around the list item? No idea!
  return (
    <div className="DealListItem">
      <ResourceList.Item media={media}
        persistActions={true}
        accessibilityLabel={"Deal with " + nameText + " for " + productName}
        shortcutActions={shortcutActions}
      >
        <div className="DealListItem__Main">
          <div className="DealListItem__Profile">
            <a href={`https://instagram.com/${influencer.igHandle}`} target="_blank" rel="noopener noreferrer" ><h3 className="DealListItem__Title">{nameText}</h3></a>
            <div className="DealListItem__Status">{statusText}</div>
          </div>
          <div className="DealListItem__Post">
            <div className="DealListItem__Likes">
              {likes}
            </div>
            <div className="DealListItem__Tip">
              {tipDiv}
            </div>
          </div>
          {downloadUrl && <Button download url={downloadUrl} plain>Download</Button>}
        </div>
      </ResourceList.Item>
    </div>
  )

}

function penniesToString(pennies) {
  return `$${(pennies / 100.0).toFixed(2)}`
}

export default DealListItem;