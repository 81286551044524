import React from 'react';
import { Route, Redirect } from "react-router-dom";
import Login from './Login';
import Console from './Console';
import Service from '../api_service';
import './admin.css';

class AdminIndex extends React.Component {
  state = {};

  componentDidMount() {
    this.login();
  }

  render() {
    let sessionHeader;
    if (this.state.api) {
      let nameLabel;
      if (sessionStorage.name) {
        nameLabel = `Hi, ${sessionStorage.name}`;
      }
      sessionHeader = (
        <div className="dark row stretch-width admin space-between align-center">
          <p style={{marginLeft: '20px'}}>{nameLabel}</p>
          <a style={{marginRight: '20px'}} onClick={this.logout}>Logout</a>
        </div>
      );
    }
    var redirect;
    if (this.state.logout || (window.location.pathname !== '/app/admin' && !this.state.api)) {
      redirect = <Redirect to="/app/admin" />
    }
    return (
      <div className="stretch-width column align-center dark">
        { sessionHeader }
        { redirect }
        <Route exact path='/app/admin' render={(p)=><Login login={this.login} {...p} />}/>
        <Route path="/app/admin/console" render={(p)=><Console api={this.state.api} {...p} />}/>
      </div>
    );
  }

  login = () => {
    const { admin, _id, token } = sessionStorage;
    var loggedIn = !!(admin && _id && token);

    if (loggedIn) {
      this.setState({api: new Service(_id, token)});
    }
  }

  logout = () => {
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('_id');
    sessionStorage.removeItem('name');
    this.setState({logout: true, api: null});
  }
}

export default AdminIndex;
