import React from 'react';
import API from '../api_service.js';
import { Redirect } from 'react-router-dom';

class Admin extends React.Component {
  state = {
    email: "",
    password: ""
  };
  api = new API("3");

  componentDidMount() {
    const { _id, admin, token } = sessionStorage;
    if (_id && admin && token) {
      this.setState({success: true});
    }
  }

  submit = async () => {
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({statusMessage: "Enter your email and password."});
    } else {
      this.setState({statusMessage: null, loading: true});
      const { data: response } = await this.api.Login({
        user: email,
        password: password,
        device: {
          name: 'Admin Console'
        }
      });
      if (response.status === 'SUCCESS') {
        const { token, admin, _id } = response.message;
        if (!admin) {
          this.setState({statusMessage: "This is not an admin account."});
          this.setState({loading: false});
        } else {
          sessionStorage.setItem('_id', _id);
          sessionStorage.setItem('admin', admin);
          sessionStorage.setItem('token', token);

          this.setState({statusMessage: "Fetching profile."});
          var profile = (await new API(_id, token).ReloadProfile()).data.message;
          this.setState({loading: false});
          if (!profile || !profile._id) {
            this.setState({statusMessage: "Error fetching profile."});
          } else {
            sessionStorage.setItem('name', profile.firstName || profile.minstagram.username);
            this.setState({success: true});
            this.props.login();
          }
        }
      } else {
        this.setState({statusMessage: "Error authenticating."});
        this.setState({loading: false});
      }
    }
  }

  render() {
    var redirect;
    if (this.state.success) {
      redirect = <Redirect to="/app/admin/console" />
    }
    var statusMessage = this.state.statusMessage ? (
      <label>{this.state.statusMessage}</label>
    ) : null;
    return (
      <div className="dark room-up-top">
        {redirect}
        <form className="column align-center" onSubmit={(e)=>e.preventDefault()}>
          <label>
            Admin Console Login
          </label>
          <div className="form-field">
            <label>
              Email
            </label>
            <input type="text" name="email" value={this.state.email} onChange={(e)=>this.setState({email: e.target.value})} />
          </div>
          <div className="form-field">
            <label>
              Password
            </label>
            <input type="password" name="password" value={this.state.password} onChange={(e)=>this.setState({password: e.target.value})} />
          </div>
          {statusMessage}
          <div className="submit-row">
            <input type="submit" className="primary" value="Login" onClick={() => { this.submit()}} />
          </div>
        </form>
      </div>
    );
  }
}

export default Admin;
