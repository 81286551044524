import React, { useState, useEffect, useContext } from 'react';
import { AppProvider as PolarisAppProvider } from '@shopify/polaris';
import { Provider as AppBridgeProvider, Context as ShopifyAppBridgeContext } from '@shopify/app-bridge-react';
import { Redirect as ShopifyRedirect } from '@shopify/app-bridge/actions';
import { Switch, Link, Route, useLocation, useHistory } from "react-router-dom";
import Product from './Product';
import Account from './Account';
import { Session } from './session.js';
import Deals from './Deals.js';
import { Default as API } from '../api_service';
import './Main.css';
import { bugsnagClient } from '../services/bugsnag'
import MessageDisplay from '../modules/messagedisplay/MessageDisplay'

const ShopifyAPIKey = '01d859927a9dc9f1377984778b676409';

// production app should always redirect.
// development app should not.
const isProduction = window.location.href.startsWith('https://shopify.guru.club') || window.location.href.startsWith('https://master.d1hdqscivxkgz7.amplifyapp.com')
const isTopLevel = window.top === window.self;

function SIndex({session, login: setGlobalSession}) {

  const location = useLocation();
  const history = useHistory()
  const queryParams = {};
  for (const [key, value] of new URLSearchParams(location.search)) {
    queryParams[key] = value;
  }
  if (location.search) {
    queryParams.matchString = location.search.slice(1).replace(/(hmac=\w+&)|(&hmac=\w+)/, '');
  }

  const login = (session) => {
    session.logout = () => {
      if (storageAvailable('sessionStorage')) {
        window.sessionStorage.clear();
      }
      if (storageAvailable('localStorage')) {
        window.localStorage.clear();
      }
      setGlobalSession(new Session());
    }
    setGlobalSession(session);
  }

  bugsnagClient.leaveBreadcrumb("Loaded SIndex", {
    ...queryParams,
    pathname: location.pathname,
    topLevel: window.top === window.self
  })

  // instantiating Shopify app will redirect ASAP, which we want
  // forceRedirect requires that SESSION is AUTHENTICATED,
  // or else Shopify will redirect to the console before the token is created,
  // which means that the app will never install.
  // There ARE some steps during install where we COULD redirect (e.g. if the pathname is /authorize)
  // because it will only ever get there after the user has created an Auth Token.
  // but to keep things simple, we will only redirect once the user is fully logged in (thus has gone through all the steps)
  const willRedirect = session.authenticated && isProduction && isTopLevel

  const sessionFromStorage = sessionFromStorageMatching(queryParams.shop)
  const canRestoreSession = sessionFromStorage && sessionFromStorage.authenticated

  const willActivateCharge = queryParams.charge_id && /authorize/.test(location.pathname)
  const willRestoreSession = !willActivateCharge && !session.authenticated && canRestoreSession

  const [installError, setInstallError] = useState(null)
  useEffect(() => {
    if (willActivateCharge) { // Charge activation request (after approving charge)
      API.ActivateCharge(queryParams.charge_id).then(() => {
        store({needs_confirm_payment: false})
        history.push('/app/shopify/account')
      }).catch(setInstallError)
    } else if (willRestoreSession) {
      login(sessionFromStorage)
    }
  }, [])

  if (installError) {
    throw installError
  } else if (willActivateCharge || willRestoreSession) {
    return (
      <div className="no-backgr">
        <MessageDisplay message="Please wait, we're redirecting your request." />
      </div>
    )
  } else {

    // if it reaches this point, then shopDomain should exist either in session
    // or in queryParams.
    // the only case that it won't be present, is if:
    // 1. user has just activated their charge (thus has redirected here with no shop in query params),
    // AND 2. user has no sessionStorage available, so we weren't able to store their shop when their initially installed
    // in that case, we let them know that a reload is required (and notify bugsnag to make sure that this)
    // isn't happening all the time

    const shopDomain = (session.shop && session.shop.myshopify_domain)
      || queryParams.shop

    if (!shopDomain) {
      bugsnagClient.leaveBreadcrumb("Missing domain - cant attach metadata to error", {
        ...queryParams,
        sessionStorageAvailabe: storageAvailable('sessionStorage'),
        pathname: location.pathname,
        topLevel: window.top === window.self
      })
      bugsnagClient.notify(new Error("No shopDomain"));

      return (
        <div className="no-backgr">
          <MessageDisplay message="Unable to find authentication in Session Storage, an app reload is required." />
        </div>
      )
    } else {
      // now, we know that shopDomain is present, so App Bridge can be loaded
      const ShopifyAppConfig = {
        apiKey: ShopifyAPIKey,
        shopOrigin: shopDomain,
        forceRedirect: willRedirect
      };

      return (
        <div className="no-backgr">
          <PolarisAppProvider>
            <AppBridgeProvider config={ShopifyAppConfig}>
              <AppBridgeSetupLayer>
                { session.authenticated ?
                  (<>
                    {SandboxFooter()}
                    <AuthorizedApp session={session}
                      setGlobalSession={setGlobalSession} pathname={location.pathname} />
                  </>)
                  :
                  (
                    <InstallationLayer login={login} queryParams={queryParams}/>
                  )
                }
              </AppBridgeSetupLayer>
            </AppBridgeProvider>
          </PolarisAppProvider>
        </div>
      );
    }
  }
}

function InstallationLayer({login, queryParams}) {
  const [installError, setInstallError] = useState(null)
  const app = useContext(ShopifyAppBridgeContext)
  function redirect(to, appBridgeRedirectType, fallbackUrl) {
    if (isTopLevel) {
      window.location.assign(fallbackUrl);
    } else {
      ShopifyRedirect.create(app).dispatch(appBridgeRedirectType, to);
    }
  }

  useEffect(() => {
    function handleLoginResponse(message) {
      if (message.nonce && message.required_scope) { // response has a nonce value
        // meaning we need to authorize permissions from the user to continue
        const {
          nonce,
          required_scope
        } = message;
        const scope = required_scope.join(',');
        const redirectUri = window.location.origin +
          '/app/shopify/authorize&state=' + nonce;

        const permissionPath = '/oauth/authorize?client_id=' + ShopifyAPIKey +
          '&scope=' + scope + '&redirect_uri=' + redirectUri + '&grant_options[]=';
        redirect(permissionPath, ShopifyRedirect.Action.ADMIN_PATH, `https://${queryParams.shop}/admin${permissionPath}`)
      } else {
        // no nonce value with required scope, should mean that authentications succeeded
        const {
          payment_confirmation_url,
          user,
          token,
          shop
        } = message;
        const externalreference = 'shopify:' + shop._id;

        store({shop, token, user, externalreference, needs_confirm_payment: !!payment_confirmation_url});

        if (payment_confirmation_url) {
          redirect(payment_confirmation_url, ShopifyRedirect.Action.REMOTE, payment_confirmation_url)
        } else {
          const session = new Session(user, shop, token, externalreference);
          login(session);
        }
      }
    }

    if (queryParams.state) { // 'state' is present, then an auth token was just created.
      API.AuthorizeShopify(
        queryParams, `${window.location.origin}/app/shopify/authorize`
      ).then(handleLoginResponse).catch(setInstallError)
    } else { // Initial install request
      API.InstallShopify(
        queryParams, `${window.location.origin}/app/shopify/authorize`
      ).then(handleLoginResponse).catch(setInstallError)
    }
  }, [])

  if (installError) {
    throw installError
  } else return (
    <MessageDisplay message="Please wait, we're redirecting your request." />
  )
}

function AppBridgeSetupLayer({children}) {

  const app = useContext(ShopifyAppBridgeContext)
  const history = useHistory()

  // Report all App Bridge errors to bugsnag
  useEffect(() => {
    const unsubscribe = app.error(({type, action, message}) => {
      bugsnagClient.notify(new Error(`Shopify App Bridge error message: ${message}; action: ${JSON.stringify(action)}; type: ${type}`))
    })
    return unsubscribe
  }, [app])

  // breadcrumb each App Bridge action
  useEffect(() => {
    const unsubscribe = app.subscribe((data) => {
      bugsnagClient.leaveBreadcrumb("App Bridge Action", JSON.stringify(data))
    })
    return unsubscribe
  })

  // override App Bridge Redirects w/ client-side routing
  useEffect(() => {
    const unsubscribe = app.subscribe(ShopifyRedirect.ActionType.APP, function(redirectData) {
      let { path } = redirectData;
      path = path.startsWith('/app/shopify') ? path : '/app/shopify' + path;
      history.push(path)
    });
    return unsubscribe
  })

  return children
}

function AuthorizedApp({session, setGlobalSession}) {
  function updateSession(updateData) {
    store(updateData);
    setGlobalSession(session.updating(updateData));
  }

  if (session.connected) {
    return (
      <Switch>
        <Route path={ '/app/shopify/products/:product_id' }>
          <Product session={session} />
        </Route>
        <Route path={ '/app/shopify/sales' }>
          <Deals session={session}/>
        </Route>
        <Route path={ '/app/shopify/account' }>
          <Account session={session} updateSession={updateSession}/>
        </Route>
        <Route>
          <Account session={session} updateSession={updateSession}/>
        </Route>
      </Switch>
    );
  } else {
    return (
      <Account session={session} updateSession={updateSession}/>
    );
  }
}

function sessionFromStorageMatching(domain) {
  try {
    if (!storageAvailable('sessionStorage')) {
      return new Session();
    }

    const initValues = ['user', 'shop', 'token', 'externalreference'].map(key => {
      try {
        return JSON.parse(window.sessionStorage.getItem(key) || 'null');
      } catch (err) {
        // item returned was not JSON-parsable (probably due to migration from past version)
        return null;
      }
    })

    const shop = initValues[1], token = initValues[2];

    if (shop && (!domain || shop.myshopify_domain === domain) && token)
      return new Session(...initValues);
    else return new Session();
  } catch (err) {
    console.error(err);
    return new Session();
  }
}

function store(data) {
  if (storageAvailable('sessionStorage')) {
    for (let key of Object.keys(data)) {
      window.sessionStorage.setItem(key, JSON.stringify(data[key]));
    }
  }

  if (storageAvailable('localStorage') && data['shop']) {
    window.localStorage.setItem('shop', JSON.stringify(data['shop']));
  }
}

/**
 *
 * @param {String} type e.g. "sessionStorage"
 */
function storageAvailable(type) {
  try {
      const storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
  } catch(e) {
      return false;
  }
}

function SandboxFooter() {
  if (/^https:\/\/\w+\.ngrok/.test(window.location.href)) {
    const links = [
      {
        title: 'Account',
        path: '/app/shopify/account'
      },
      {
        title: 'Deals',
        path: '/app/shopify/sales'
      }
    ]
    return (
      <div className="stretch-width sandbox-footer">{
        links.map(link => {
          return <Link style={{color: 'black'}} key={link.title} to={link.path}>{link.title}</Link>;
        })
      }</div>
    )
  }
}

export default SIndex;
