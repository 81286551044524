module.exports = {
  categories: [
    'Cosmetics & Self-Care',
    'Nutrition',
    'Apparel',
    'Jewelry',
    'Accessories',
    'Home',
    'Pet Care',
    'Travel',
    'Events',
    'Fitness',
    'Restaurants & Nightlife'
  ]
}
