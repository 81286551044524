import React from 'react';
import Burger from './Burger.js';
import InfluencerAbout from './InfluencerAbout.js';
import BrandAbout from './BrandAbout.js';
import ScrollToTop from './ScrollToTop.js';
import NavMenuLink from './NavMenuLink.js';
import ContactUs from './ContactUs.js';
import Portal from './Portal.js';
import About from './About.js';
import Terms from './Terms';
import InstaLink from './InstaLink.js';
import { BrowserRouter as Router, Route, Redirect, NavLink, Switch } from "react-router-dom";
const logo = require('./Images/Guru_Logo_Circle.png')

const Influencer = ({match}) => {
  return (
    <InfluencerLanding match={match}/>
  );
};

class InfluencerLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {topBarClass: "topbar", contactUsClass: 'light-link',
    tabs: [
      {path: `/app/brand`, name: "brand", className: "light-link heavy", render: (props) => (<BrandAbout isDesktop={this.state.isDesktop} {...props} />)},
      {path: `/app/shopper`, name: "shopper", className: "light-link heavy", render: (props) => (<InfluencerAbout {...props} />)},
      {path: `/app/about`, name: "about", className: "light-link", render: (props) => (<About {...props} />)},
       {path: `/app/contact`, name: "contact us", className: "light-link", render: (props) => (<ContactUs {...props} />)}
    ],
    topBarStyle: {justifyContent: 'center'}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isDesktop !== this.state.isDesktop) {
      this.updateTopBarJustification();
    }
  }

  updateTopBarJustification = () => {
    const justify = this.state.isDesktop ? 'center' : 'space-between';
    this.setState({topBarStyle: {justifyContent: justify}});
  }

  render() {
    const intro = (
      <section className="intro">
        <div style={this.state.topBarStyle} className={this.state.topBarClass}>
          <div className="title-group">
            <img src={logo} alt="" style={{height: '35px', margin: '0 -10px 0 10px'}}/>
            <label className="title"><NavLink to={`/app/`}>guru.club</NavLink></label>
          </div>
          {this.state.isDesktop ?
          <div className="nav-menu">
            {this.state.tabs.map((tab) => <NavMenuLink key={tab.name} className={tab.className} title={tab.name} destination={tab.path} />)}
          </div>
          :
          <Burger tabs={this.state.tabs} />
          }
        </div>
      </section>
    );
    return(
      <div className="wrap">
        <Router>
          <ScrollToTop>
            <div>
            	{intro}
              <Switch>
                {this.state.tabs.map((tab) => <Route key={tab.name} path={tab.path} render={tab.render}/> )}
                <Route path="/app/terms" render={(props) => <Terms {...props} />} />
                <Route exact path="/app/" render={(props) => <Portal {...props} innerHeight={this.state.innerHeight} innerWidth={this.state.innerWidth}/>} />
                <Redirect to="/app/" />
              </Switch>
              <div className="footer">
                <InstaLink style={{width: '35px'}}/>
              </div>
            </div>
          </ScrollToTop>
        </Router>
      </div>
    )
  }

  handleScroll = (event) => {
    let scrollTop = event.target.scrollingElement.scrollTop;
    if (scrollTop <= 0) {
      this.setState({topBarClass: "topbar"});
    } else if (this.state.topBarClass !== "topbar add-shadow") {
      this.setState({topBarClass: "topbar add-shadow"});
    }
  }

  updateDimensions = () => {
    const { innerWidth, innerHeight } = window;
      this.setState({isDesktop: innerWidth > 991, innerWidth: innerWidth, innerHeight: innerHeight});
  }

  componentWillMount() {
      this.updateDimensions();
  }
  componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
      window.addEventListener("scroll", this.handleScroll);
      this.updateTopBarJustification();
  }
  componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
      window.removeEventListener("scroll", this.handleScroll);
  }
}

export default Influencer;
