import React from 'react';

class Terms extends React.Component {
  render() {
    return (
      <div className="room-up-top">
        <div className="about-div dark">
      <p>Last updated on: April 23, 2019</p>
<p>By signing up for, or using the Guru.Club services or any of the services of Guru.Club LLC. you are agreeing to be bound by the following terms and conditions (“Terms of Service”). The services offered by Guru.Club under the Terms of Service include various products and services to help you sell goods and services to online. Any such services offered by Guru.Club are referred to in these Terms of Services as the “Services”. Any new features or tools which are added to the current Services shall be also subject to the Terms of Service. You can review the current version of the Terms of Service at any time at www.guru.club/terms. Guru.Club reserves the right to update and change the Terms of Service by posting updates and changes to the Guru.Club website. You are advised to check the Terms of Service from time to time for any updates or changes that may impact you.</p>




<h1>1. Account Terms</h1>
<ol>
<li>You must be 18 years or older or at least the age of majority in the jurisdiction where you reside or from which you use this Service.</li>
<li>To access and use the Services, you must register for a Guru.Club account (“Account”) by providing your full legal name, current address, phone number, a valid email address, and any other information indicated as required. Guru.Club may reject your application for an Account, or cancel an existing Account, for any reason, in our sole discretion.</li>
<li>You acknowledge that Guru.Club will use the email address you provide as the primary method for communication.</li>
<li>You are responsible for keeping your password secure. Guru.Club cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password.</li>
<li>You are responsible for all activity and content such as photos, images, videos, graphics, written content, audio files, code, information, or data uploaded, collected, generated, stored, displayed, distributed, transmitted or exhibited on or in connection with your Account (“Materials”).</li>
<li>A breach or violation of any term in the Terms of Service, including the AUP, as determined in the sole discretion of Guru.Club will result in an immediate termination of your services.</li>
</ol>
<h1>2. Guru.Club Rights</h1>
<ol>
<li>We reserve the right to modify or terminate the Service for any reason, without notice at any time.</li>
<li>We reserve the right to refuse service to anyone for any reason at any time.</li>
<li>We may, but have no obligation to, remove Materials and suspend or terminate Accounts if we determine in our sole discretion that the goods or services offered via a store, or the Materials uploaded or posted to a store, violate these Terms of Service.</li>
<li>Verbal or written abuse of any kind (including threats of abuse or retribution) of any Guru.Club customer, Guru.Club employee, member, or officer will result in immediate Account termination.</li>
<li>Guru.Club does not pre-screen Materials and it is in our sole discretion to refuse or remove any Materials from the Service.</li>
<li>We reserve the right to provide our services to your competitors and make no promise of exclusivity in any particular market segment.</li>
</ol>
<h1>3. Limitation of Liability</h1>
<ol>
<li>You expressly understand and agree that Guru.Club shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use of or inability to use the service.</li>
<li>In no event shall Guru.Club or our suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with our site, our services or these Terms of Service (however arising including negligence). You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, Guru.Club partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of these Terms of Service or the documents it incorporates by reference your violation of any law or the rights of a third party.</li>
<li>Your use of the Service is at your sole risk. The Service is provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory.</li>
<li>Guru.Club does not warrant that the Service will be uninterrupted, timely, secure, or error-free.</li>
<li>Guru.Club does not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.</li>
<li>Guru.Club does not warrant that the quality of any products, services, information, or other Materials purchased or obtained by you through the Service will meet your expectations, or that any errors in the Service will be corrected.</li>
</ol>
<h1>4. Intellectual Property and Customer Content</h1>
<ol>
<li>We do not claim any intellectual property rights over the Materials you provide to the Guru.Club service. All Materials you upload remains yours. You can remove your Guru.Club store at any time by deleting your Account.</li>
<li>By uploading Materials, you agree: (a) to allow other internet users to view the Materials you post publicly to your store; (b) to allow Guru.Club to store, and in the case of Materials you post publicly, display, your Materials; and (c) that Guru.Club can, at any time, review all the Materials submitted to its Service, although Guru.Club is not obligated to do so.</li>
<li>You retain ownership over all Materials that you upload to a Guru.Club store; however, by making your store public, you agree to allow others to view Materials that you post publicly to your store. You are responsible for compliance of the Materials with any applicable laws or regulations.</li>
<li>We will not disclose your confidential information to third parties (“Your Confidential Information”), except as required in the course of providing our services. Your Confidential Information includes any Materials or information provided by you to us which is not publicly known. Your Confidential Information does not include information that: (a) was in the public domain at the time we received it; (b) comes into the public domain after we received it through no fault of ours; (c) we received from someone other than you without breach of our or their confidentiality obligations; or (d) we are required by law to disclose.</li>
<li>Guru.Club shall have the non-exclusive right and license to use the names, trademarks, service marks and logos associated with your store to promote the Service.</li>
</ol>
<h1>5. Modifications to the Service and Prices</h1>
<ol>
<li>Prices for using the Services are subject to change upon 30 days’ notice from Guru.Club. Such notice may be provided at any time by posting the changes to the Guru.Club Site (Guru.Club.com) or the administration menu of your Guru.Club store via an announcement.</li>
<li>Guru.Club reserves the right at any time, and from time to time, to modify or discontinue, the Service (or any part thereof) with or without notice.</li>
<li>Guru.Club shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.</li>
</ol>
<h1>6. Cancellation and Termination</h1>
<ol>
<li>You may cancel your Account at any time by emailing shop@Guru.Club and then following the specific instructions indicated to you in Guru.Club’s response.</li>
<li>Upon termination of the Services by either party for any reason:</li>
<ol>
<li>Guru.Club will cease providing you with the Services and you will no longer be able to access your Account;</li>
<li>unless otherwise provided in the Terms of Service, you will not be entitled to any refunds of any Fees, pro rata or otherwise;</li>
<li>any outstanding balance owed to Guru.Club for your use of the Services through the effective date of such termination will immediately become due and payable in full; and</li>
<li>your store will be taken offline.</li>
</ol>
</ol>
<h1>7. Privacy & Data Protection</h1>
<p>Guru.Club is firmly committed to protecting the privacy of your personal information and the personal information of your customers. By using the Service, you acknowledge and agree that Guru.Club’s collection, usage and disclosure of this personal information is governed by our Privacy Policy.</p>
<h1>8. Payment of Fees</h1>
<p>Each review made through Guru.Club will result in a fee equal to 5% of its original price. This fee will be handled via the third-party application connecting merchant and Guru.Club (e.g. Shopify).</p>
{/* <p>Each product sold has a designated discount amount contingent on a set of agreements between customer and merchant. If Guru.Club finds that the customer did not fulfill their end of the agreements, Guru.Club may reward part of the amount discounted back to the merchant in the form of usage credits. Usage credits may be used in place of money for the fees described earlier in the section.</p> */}
        </div>
      </div>
    )
  }
}

export default Terms;
