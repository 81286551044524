import React from 'react';
import { FooterHelp, Link, Card, Button, Page, DataTable, TextField, Spinner, ButtonGroup} from '@shopify/polaris';
import './Product.css';
import { TitleBar } from '@shopify/app-bridge-react';

class Product extends React.Component {
  constructor(props) {
    super(props);
    const product_id = window.location.pathname.match(/products\/(\w+)/)[1];
    this.state = { loading: false, savingDiscount: false, savingVariants: false, product_id };
  }

  discountPrice = () => {
    return this.state.product.price - this.state.rebate;
  }

  async componentDidMount() {
    const { product_id } = this.state;
    var product;
    try {
      const storedProducts = await this.props.session.inventory.allProducts();
      if (storedProducts) {
        product = storedProducts.find(stored => stored._id === product_id);
      }
      if (product)
      {
        var maxRebate = product.price / 100;
        this.setState({ product, rebate: product.price - product.discountPrice, maxRebate, stockCounts: this.originalStockCounts(product) });
      }
    } catch (err) {
      
    }
  }

  saveDiscount = async () => {
    const { rebate, product } = this.state;
    if (rebate > product.price) {
      this.setState({rebateError: 'Rebate cannot be larger than price amount.'});
    } else if (rebate < 50 && rebate > 0) {
      this.setState({rebateError: 'Rebate cannot be between $0.01 and $0.49.'});
    } else {
      this.setState({savingDiscount: true, discountError: null, rebateError: null});
      var state = { savingDiscount: false };
      try {
        const newDiscount = rebate / product.price;
        var { stock } = product;
        for (var i = 0; i < stock.length; i++) {
          stock[i].discountPrice = Math.round((1 - newDiscount) * stock[i].price);
        }
        const productUpdate = {
          _id: product._id,
          discount: newDiscount,
          discountPrice: this.discountPrice(),
          stock: stock
        };
        await this.props.session.inventory.updateProduct(productUpdate);
        state.product = Object.assign(product, productUpdate);
      } catch (err) {
        state.discountError = 'Unable to save discount.';
      }
      this.setState(state);
    }
  }

  saveVariants = async () => {
    this.setState({savingVariants: true, variantsError: null });
    var state = { savingVariants: false };
    try {
      const { stock} = this.state.product;
      const { stockCounts } = this.state;
      for (var i = 0; i < stockCounts.length; i++) {
        stock[i].count = stockCounts[i];
      }
      await this.props.session.inventory.updateProduct({ _id: this.state.product._id, stock: stock });
      const { product } = this.state;
      product.stock = stock;
      state.product = product;
      state.stockCounts = this.originalStockCounts(product);
    } catch (err) {
      state.variantsError = 'Unable to save variants.';
    }
    this.setState(state);
  }

  originalStockCounts = (product) => {
    return product.stock.map(stock => stock.count);
  }

  render() {
    if (!this.state.product) return (
      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Spinner size="large" />
      </div>
    );

    const discountChanged = this.state.rebate !== this.state.product.price - this.state.product.discountPrice;
    const quantityChanged = this.state.stockCounts.reduce((accum, count, index) => { return (accum || count !== this.state.product.stock[index].count) }, false);

    return (
      <>
        <Page>
          <TitleBar
            title={this.state.product.name}
            breadcrumbs={[{content: 'Products', url: '/app/shopify/products', target: 'APP'}]}
          />
          <Card sectioned title='Assign a Rebate'>
            <p>Original Price</p>
            <p>${(this.state.product.price / 100).toFixed(2)}</p>
            <div style={{marginTop: '40px', marginBottom: '40px'}}>
              <p>Rebate</p>
              <div style={{marginTop: '10px', maxHeight: '50px', display: "flex", flexDirection: "row", alignItems: "baseline", fontSize: "1.4rem"}}>
                $
                <TextField
                  type="number"
                  error={this.state.rebateError}
                  value={this.state.rebate / 100}
                  min={0}
                  max={this.state.maxRebate}
                  onChange={(rebate) => {
                    rebate = Math.min(this.state.maxRebate,rebate);
                    rebate *= 100;
                    this.setState({rebate});
                  }}
                  align="right"
                />
              </div>
            </div>
            <ButtonGroup>
              <Button disabled={!discountChanged || this.state.savingDiscount} onClick={() => this.setState({rebate: this.state.product.price - this.state.product.discountPrice})}>Cancel</Button>
              <Button disabled={!discountChanged} onClick={() => this.saveDiscount()} loading={this.state.savingDiscount} primary>Save</Button>
            </ButtonGroup>
            <p style={{marginTop: '20px'}}>
            If a rebate is assigned, customers will see the cashback offer as 30¢ less than what is shown here, as that amount is lost to transactional fees. "Rebated Price" shown above is the final amount routed to you at checkout. Learn about{' '}
              <Link external url={`https://guru.club/for-brands/brand-support/#how-much-should-i-discount`}>
              how much to discount.
              </Link>
            </p>
          </Card>
          <Card title="Variants" sectioned>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '40px'}}>
              <ButtonGroup>
                <Button disabled={!quantityChanged || this.state.savingVariants} onClick={() => this.setState({stockCounts: this.originalStockCounts(this.state.product)})}>Cancel</Button>
                <Button disabled={!quantityChanged} onClick={this.saveVariants} loading={this.state.savingVariants} primary>Save</Button>
              </ButtonGroup>
            </div>
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text',
                'numeric'
              ]}
            headings={[
              'SKU',
              'Color',
              'Size',
              'Quantity'
            ]}
              resourceName={{singular: 'variant', plural: 'variants'}}
              rows={this.state.stockCounts.map((stockCount, index) => {
                const stock = this.state.product.stock[index];
                const countDiv = (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', margin: 'none'}}>
                    <TextField
                    label="Quantity"
                    labelHidden={true}
                    min={0}
                    max={stock.count}
                    placeholder={this.state.product.stock[index].count}
                    type="number"
                    value={stockCount}
                    onChange={(value) => {
                      if (!value || isNaN(value)) value = stock.count;
                      value = parseInt(value);
                      if (value >= 0 && value <= stock.count) {
                        const { stockCounts } = this.state;
                        stockCounts[index] = value;
                        this.setState({stockCounts: stockCounts});
                      }
                    }}
                  />
                  </div>);
                return [stock.sku, stock.color, stock.size, countDiv];
              })}
            />
          </Card>
          <FooterHelp>
          Learn more about{' '}
            <Link external url={`https://guru.club/for-brands/brand-support/#listing-products`}>
            product discounts.
            </Link>
          </FooterHelp>
        </Page>
      </>
    );
  }
}

export default Product;
