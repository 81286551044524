import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import React, { Component } from 'react';

class Burger extends Component {
  constructor(props) {
    super(props);
    this.state = {menuOpen: false};
  }

  showSettings (event) {
    event.preventDefault();
  }

  render() {
    return (
        <Menu onStateChange={this.handleStateChange} isOpen={this.state.menuOpen} right width={"100%"} customBurgerIcon={<img alt={"navigate"} src={require('./Images/icons8-menu-480.png')} />}>
          {this.props.tabs.map((tab) => <NavLink exact onClick={this.closeMenu} key={tab.name} to={tab.path} activeClassName="bm-item-active">{tab.name}</NavLink>)}
        </ Menu>
      )
  }

  closeMenu = () => {
    this.setState({menuOpen: false});
  }

  handleStateChange = (state) => {
    this.setState({menuOpen: state.isOpen});
  }
}

export default Burger;
