import React from 'react';

const banner = (
  <div className="portal-title">
    <img alt={""} src={require('./Images/Guru_Logo_Circle.png')} style={{width: '75px'}}/>
    guru.club
  </div>
);

export default banner;
