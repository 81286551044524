import './Gallery.css';
import React from 'react';
import { Scrollable } from '@shopify/polaris';
import GalleryItem from './GalleryItem/GalleryItem'

function Gallery({toTip, setTip}) {
    return (
        <Scrollable horizontal={true} vertical={false} shadow>
            <div className="gallery">
                {toTip.map(toTip => {
                    return (
                        <GalleryItem
                            key={toTip._id}
                            deal={toTip}
                            updateTip={(amount)=>setTip(toTip._id, amount)}
                        />)
                })}
            </div>
        </Scrollable>
    )
}

export default Gallery;