import React from 'react';
import API from './api_service';
import Validators from './helpers/Validators.js';
import './reset_pass.css';

class ResetPass extends React.Component {

  constructor(props) {
    super(props);

    const api = new API();
    const queryString = window.location.search.substring(1);
    const params = queryString
      .split('&')
      .reduce((obj, param) => {
        param = param.split('=');
        obj[param[0]] = param[1];
        return obj
      }, {});
    if (!params.email || !params.token) {
      this.state = {
        errorMessage: "Invalid link."
      };
    } else {
      this.state = {
        email: params.email,
        token: params.token
      };
    }

    this.state.api          = api;
    this.state.pass         = "";
    this.state.confirm_pass = "";
  }

  async componentWillMount() {
    if (this.state.token) {
      this.setState({
        loading: true
      });

      try {
        await this.state.api.CheckTokenIsValid(this.state.token);
        this.setState({
          loading: false
        });
      } catch (err) {
        if (err.message === "Expired") {
          this.setState({
            loading: false,
            errorMessage: "This link has expired."
          });
        } else {
          this.setState({
            loading: false,
            errorMessage: "Invalid link."
          });
        }
      }
    }
  }

  change_pass = async () => {
    if (this.state.pass !== this.state.confirm_pass) {
      this.setState({secondaryErrorMessage: "Passwords do not match."});
    } else if (!Validators.password.test(this.state.pass)) {
      this.setState({secondaryErrorMessage: "Password must be 8-64 characters."});
    } else {
      try {
        this.setState({secondaryErrorMessage: null, secondaryLoading: true});
        await this.state.api.ChangePassword({
          new_pass: this.state.pass,
          token: this.state.token,
          email: this.state.email
        });
        this.setState({secondaryLoading: false, success: true});
      } catch (err) {
        this.setState({secondaryErrorMessage: "An error occurred. Your link may have expired.", secondaryLoading: false});
      }
    }
  }

  render() {
    var display;
    if (this.state.errorMessage) {
      display = (<p className="reset">{this.state.errorMessage}</p>);
    } else if (this.state.success) {
      display = (<p className="reset">Password updated.</p>);
    } else if (this.state.loading) {
      display = (<p className="reset">Validating link.</p>);
    } else {
      var submit = this.state.secondaryLoading ? (
        <p className="reset">Submit</p>
      ) : (
        <a id="reset_submit" onClick={() => {this.change_pass()}}>Submit</a>
      );

      var errorMessage = this.state.secondaryErrorMessage ? (
        <p className="reset">{this.state.secondaryErrorMessage}</p>
      ) : null;
      display = (
        <>
          <p className="reset">Reset Password</p>
          <p className="reset" style={{fontSize: '15px'}}>Enter a new password for your account.</p>
          <input className="reset" placeholder="Password" type="password" onChange={e=>this.setState({pass: e.target.value})} value={this.state.pass} />
          <input className="reset" placeholder="Confirm Password" type="password" onChange={e=>this.setState({confirm_pass: e.target.value})} value={this.state.confirm_pass} />
          {errorMessage}
          {submit}
        </>
      );
    }

    return (
      <div className="dark column stretch-height stretch-width centered align-center" style={{minWidth: "300px", minHeight: "500px"}}>
        {display}
      </div>
    );
  }

}

export default ResetPass;
