import React from 'react';
import { Redirect } from 'react-router-dom';

const selectedTabClass = "admin tab selected";
const unselectedTabClass = "admin tab";

class ProfilesPage extends React.Component {
  state = {
    display: 'brand',
    loading: true,
    currentPage: 0,
    pages: 0,
    total: 0,
    profiles: [],
    backPath: [],
    hideRejected: true
  };

  main = React.createRef();

  loadProfiles = async (display, lastId) => {
    try {
      if (display !== this.state.display) {
        this.setState({
          loading: true,
          display: display,
          currentPage: 0,
          pages: 0,
          total: 0,
          profiles: []
        });
      } else {
        this.setState({
          loading: true
        });
      }


      var res = (await this.props.api.GetProfiles(display, lastId)).data.message;
      if (this.state.display === display) {
        const { results, total, currentPage, pages, lastId } = res;
        this.setState({
          profiles: results,
          total: total,
          currentPage: currentPage,
          pages: pages,
          lastId: lastId
        });
      }
      this.setState({loading: false});
    } catch (err) {
      console.error(err);
      this.setState({loading: false});
    }
  }

  backPage = async () => {
    this.state.backPath.pop();
    var lastId;
    if (this.state.backPath.length > 0) {
      lastId = this.state.backPath[this.state.backPath.length - 1];
    }
    await this.loadProfiles(this.state.display, lastId);
  }

  nextPage = async () => {
    var { lastId } = this.state;
    if (lastId) {
      this.state.backPath.push(lastId);
    }
    await this.loadProfiles(this.state.display, lastId);
  }

  displayProfiles = () => {
    if (this.state.loading) {
      return (
        <p className="stretch-width" style={{textAlign: "center"}}>
          Loading profiles...
        </p>
      );
    } else {
      const { profiles } = this.state;
      const columns = [
        <th key="name">Name</th>,
        <th key="status">Status</th>,
        <th key="ig">Instagram Handle</th>,
        <th key="discount">Discount</th>,
        <th key="email">Email</th>
      ];
      if (this.state.display.startsWith('brand')) {
        columns.splice(2,0,<th key="charge">Charge Status</th>);
      }

      return (
        <div className="stretch-width">
          <table className="stretch-width">
            <thead>
              <tr>
                {columns}  
              </tr>
            </thead>
            <tbody>
              {profiles.map((p) => {
                let cells = [];
                cells.push(
                  <td key="name">{p.isCreator ? p.name : p.company}</td>);
                let status;
                if (p.isCreator) {
                  if (p.lastActive) {
                    status = <p>Last active {p.lastActive}</p>;
                  }
                } else if (p.appStatus === "Pending") {
                  status = <p style={{color: '#05ACEE'}}>Awaiting approval</p>;
                } else if (p.appStatus === "Denied") {
                  status = <p style={{color: 'red'}}>Rejected</p>;
                } else if (!p.isCreator && !p.hidden) {
                  status = <p style={{color: '#0cfc2c'}}>Store is live</p>;
                } else if (!p.isCreator && p.hidden) {
                  status = <p>Hidden</p>
                }
                cells.push(<td key="status">{status}</td>);

                if (!p.isCreator) {
                  const chargeStatus = ((chargeStatus) => {
                    switch (chargeStatus) {
                      case 'N/A':
                      case 'Free Trial':
                        return <p>{chargeStatus}</p>;
                      case 'Activated':
                        return <p className='success'>{chargeStatus}</p>;
                      case 'Pending':
                        return <p className='admin highlighted'>{chargeStatus}</p>;
                      default:
                        return null;
                    } 
                  })(p.chargeStatus);
                  cells.push(<td key="charge">{chargeStatus}</td>);
                }
                cells.push(
                  <td key="ig">{p.minstagram && p.minstagram.username}</td>);
                cells.push(
                  <td key="discount">{p.isCreator ? "N/A" : String(parseInt(100 * p.discount)) + "%"}</td>
                )
                cells.push(<td key="email">{p.email}</td>);
                return (
                  <tr className="clickable" key={p._id} onClick={()=>{this.setState({redirect:p._id})}}>
                    {cells}  
                  </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

  infoHeader() {
    var indexStart = Math.max(0, (this.state.currentPage - 1) * 50 + 1);
    var indexEnd = Math.max(0, indexStart + this.state.profiles.length - 1);
    var totalInfo = (<p>{indexStart + ' to ' + indexEnd + ' of ' + this.state.total}</p>);
    var pageInfo =  (<p>{'Page ' + this.state.currentPage + ' of ' + this.state.pages}</p>);
    var loading = this.state.loading || this.state.exporting;
    var backDisabled = this.state.backPath.length <= 0 || loading ?
      "disabled" : null;
    var nextDisabled = indexEnd >= this.state.total || loading ?
      "disabled" : null;

    var back = <button disabled={backDisabled} onClick={this.backPage}>Back</button>;
    var next = <button disabled={nextDisabled} onClick={this.nextPage}>Next</button>;

    return (
      <div className="row space-around stretch-width align-center">
        {back}
        {pageInfo}
        {totalInfo}
        {next}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.api !== this.props.api) {
      this.loadProfiles('brand')
    }
  }

  componentDidMount() {
    try {
      let { storedProfiles: stored } = window.sessionStorage;
      stored = stored && JSON.parse(stored);
      if (stored && stored.profiles && stored.profiles.length > 0) {
        let { display, currentPage, pages, total, profiles, backPath } = stored;
        this.setState({
          display: display,
          currentPage: currentPage,
          pages: pages,
          total: total,
          profiles: profiles,
          backPath: backPath,
          loading: false
        });
      } else throw new Error("No stored profiles.")
    } catch (err) {
      this.loadProfiles('brand');
    }
  }

  componentWillUnmount() {
    let { display, currentPage, pages, total, profiles, backPath } = this.state;
    if (profiles && profiles.length > 0) {
      window.sessionStorage.setItem('storedProfiles', JSON.stringify({
        display: display,
        currentPage: currentPage,
        pages: pages,
        total: total,
        profiles: profiles,
        backPath: backPath
      }));
    }
  }

  toggleShowRejected = () => {
    const hideRejected = !this.state.hideRejected;
    let display = this.state.display.split(',')[0];
    if (!hideRejected) display += ',rejected';
    this.setState({hideRejected});
    this.loadProfiles(display);
  }

  export = async () => {
    this.setState({exporting: true, exportFailed: false});
    try {
      var { display, pages } = this.state;
      var currentPage = 1;
      var lastId;

      var content;
      if (display.startsWith('guru')) {
        content = [['Name', 'IG Handle', 'Email']];
      } else if (display.startsWith('brand')) {
        content = [['Company', 'Name', 'IG Handle', 'Email']];
      }

      do {
        var nextGroup = (await this.props.api.GetProfiles(display, lastId)).data.message;
        pages       = nextGroup.pages;
        lastId      = nextGroup.lastId;
        currentPage = nextGroup.currentPage;
        if (display.startsWith('guru')) {
          content = content.concat(nextGroup.results.map((p) => {
            return [p.name, p.minstagram && p.minstagram.username,
              p.email];
          }));
        } else if (display.startsWith('brand')) {
          content = content.concat(nextGroup.results.map((p) => {
            return [p.company, p.name, p.minstagram && p.minstagram.username,
              p.email];
          }));
        }
      } while (currentPage < pages);
      var csvContent = '';
      for (var i = 0; i < content.length; i++) {
        var dataString = content[i].join(';');
        csvContent += i < content.length - 1 ? dataString + '\n' : dataString;
      }

      var a = document.createElement('a');
      var mimeType = 'text/csv;encoding:utf-8';
      var filename = display + 's.csv';
      if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlock(new Blob([csvContent], {
          type: mimeType
        }), filename);
      } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([csvContent], {
          type: mimeType
        }));
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href = 'data:application/octet-stream,' + encodeURIComponent(csvContent);
      }
      this.setState({exporting: false});

    } catch (err) {
      this.setState({exporting: false, exportFailed: true});
    }
  }

  render() {
    var guruTabClass = this.state.display.startsWith('guru') ? selectedTabClass : unselectedTabClass;
    var brandTabClass = this.state.display.startsWith('brand') ? selectedTabClass : unselectedTabClass;

    var exportText;
    if (this.state.exportFailed) {
      exportText = "Export failed. Try again?";
    } else if (this.state.exporting) {
      exportText = "Exporting..";
    } else {
      exportText = "Export All";
    }
    let rejectedText;
    if (this.state.hideRejected) {
      rejectedText = 'Show Rejected';
    } else {
      rejectedText = 'Hide Rejected';
    }

    var exportDisabled = this.state.exporting && "disabled";

    var redirect;
    if (this.state.redirect) {
      redirect = <Redirect push to={"/app/admin/console/profiles/" + this.state.redirect} />;
    }

    return (
      <div className="admin dark" style={{padding: '20px'}} ref={this.main}>
        {redirect}

        <div className="row">
          <p className={brandTabClass} onClick={() => {
              if (!this.state.exporting) {
                this.loadProfiles('brand');
              }
            }}>Brands</p>
          <p className={guruTabClass} onClick={()=>{
              if (!this.state.exporting) {
                this.loadProfiles('guru');
              }
            }}>Gurus</p>
        </div>
        <div>
          <button onClick={this.toggleShowRejected}>{rejectedText}</button>
          <button disabled={exportDisabled} onClick={this.export}>{exportText}</button>
        </div>
        {this.infoHeader()}
        {this.displayProfiles()}
      </div>
    );
  }
}

export default ProfilesPage;
