import React from 'react';
import { Redirect } from 'react-router-dom';

class Deal extends React.Component {

  constructor(props) {
    super(props);
    let deal_id = props.match.params.id;
    let deals = window.sessionStorage.getItem('deals');
    if (deals) {
      deals = JSON.parse(deals);
      if (deals[deal_id]) {
        this.state = {
          deal : deals[deal_id]
        }
      }
    }
    this.state = this.state || {redirect: "/app/admin/console/deals"};
  }

  goBack = () => {
    this.props.history.goBack();
  }

  influencerbutton = (influencer) => {
    let { firstName, lastName, _id } = influencer;
    let name;
    if (firstName || lastName) {
      name = (firstName || "") + " " + (lastName || "");
    } else {
      name = influencer.igHandle;
    }

    return <button onClick={e=>{
      this.setState({redirect: "/app/admin/console/profiles/" + _id})
    }}>{name}</button>;
  }

  brandbutton = (brand) => {
    return <button onClick={e=>{
      this.setState({redirect: "/app/admin/console/profiles/" + brand._id})
    }}>{brand.company}</button>;
  }

  notificationsTable = (notifications) => {
    if (!notifications || notifications.length === 0)
      return <p className="stretch-width">User has not been reminded of this deal.</p>;

      const row = (notif) => {
        const status = notif.notified === 1 ? 
          <p className="highlight">Delivered</p> 
            :
          <p className="reject">Error</p>;

        return (
          <tr key={notif._id}>
            <td>{global.shorten(notif.createdOn)}</td>
            <td>{notif.message}</td>
            <td>{status}</td>
            <td>{notif.errorMessage}</td>
          </tr>
        );
      }

      return (
        <div className="column stretch-width align-center">
          <h5>Reminders</h5>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Message</th>
                <th>Status</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map(row)}
            </tbody>
          </table>
        </div>
      );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    } else {
      let { deal } = this.state;

      let contentDiv;
      if (deal.content && deal.content.length > 0) {
        let links = [];
        let content = deal.content[deal.content.length - 1];

        if (content.contentType === "image") {
          links.push(<a key="image" href={content.sourceUrl}>Image</a>);
        } else {
          links.push(<a key="thumbnail" href={content.thumbnailUrl} download={content.key + ".jpg"}>Thumbnail</a>);
          links.push(<a key="video" href={content.sourceUrl}>Video</a>);
        }

        if (deal.status === "Pending") {
          links.push(<a key="reject" className="admin reject" onClick={async e=>{
            if (window.confirm("Are you sure you want to reject this user's content?")) {
              await this.props.api.RejectDeal(this.state.deal._id);
              this.setState({redirect: "/app/admin/console/deals"});
            }
          }}>Reject</a>);
        }

        contentDiv = (
          <div className="column align-center">
            <p><b>Content</b></p>
            <img style={{imageFit: 'cover', maxHeight: '400px'}}
              src={content.thumbnailUrl} />
            <div className="row space-around stretch-width">
              {links}
            </div>
          </div>
        );
      }

      return (
        <div className="column stretch-width align-start">
          <button onClick={e=>this.props.history.goBack()}>
            Back
          </button>
          <div className="row stretch-width centered">
            <div className="contain-width row admin space-around">
              <div className="column align-center">
                <p><b>Details</b></p>
                <div className="column align-start">
                  <p>Created {global.shorten(deal.requested)}</p>
                  <div className="row">
                    <p>Guru</p>
                    {this.influencerbutton(deal.influencer)}
                  </div>
                  <div className="row">
                    <p>Brand</p>
                    {this.brandbutton(deal.brand)}
                  </div>
                  <p>{deal.productName}</p>
                </div>
              </div>
              <div className="column align-center">
                <p><b>
                  Status
                </b></p>
                <p>{global.statusOf(deal)}</p>
              </div>
              {contentDiv}
            </div>
          </div>
          {this.notificationsTable(deal.notifications)}
        </div>
      );
    }
  }
}

export default Deal;
