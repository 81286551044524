import React from 'react';

class NotificationsPage extends React.Component {

  state = {
    notifications: [],
    loading: true,
    message: '',
    sending: false
  }

  async componentDidMount() {
    try {
      const notifications = await this.props.api.GetNotifications();
      this.setState({loading: false, notifications: notifications});
    } catch (err) { }
  }

  updateMessage = (e) => {
    this.setState({message: e.target.value});
  }

  sendMessage = async () => {
    const { message } = this.state;
    if (!message) {
      window.alert("Cannot send a notification without a message.");
    } else if (window.confirm("Send notification?")) {
      try {
        this.setState({sending: true, errorMessage: null});
        const noti = await this.props.api.SendNotification(message);
        var { notifications } = this.state;
        notifications.splice(0, 0, noti);
        this.setState({sending: false, message: '', notifications: notifications});
      } catch (err) {
        this.setState({sending: false, errorMessage: err.message});
      }
    }
  }

  notificationsTable = () => {
    if (this.state.loading) {
      return (
        <p className="stretch-width" style={{textAlign: 'center'}}>Loading...</p>
      );
    } else if (this.state.notifications.length === 0) {
      return (
        <p className="stretch-width" style={{textAlign: 'center'}}>No notifications.</p>
      );
    } else {
      return (
        <table className="stretch-width">
          <thead>
            <tr>
              <th>Date</th>
              <th>Message</th>
              <th>Author</th>
              <th>Notified</th>
              <th>Failures</th>
            </tr>
          </thead>
          <tbody>
            {this.state.notifications.map((noti) => {
              return (
                <tr key={noti._id}>
                  <td>{noti.createdOn}</td>
                  <td>{noti.message}</td>
                  <td>{noti.author && noti.author.firstName}</td>
                  <td>{noti.notified}</td>
                  <td>{noti.errorCount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  render() {
    return (
      <div className="contain-width">
        <div className="row centered stretch-width">
          <input style={{minWidth: '200px', width: '50%', marginRight: '30px'}} value={this.state.message} onChange={this.updateMessage} placeholder={"Send a notification to all gurus"} />
          <button disabled={this.state.sending && "disabled"} onClick={this.sendMessage}>Send</button>
        </div>
        <p className="stretch-width" style={{textAlign: 'center'}}>{this.state.errorMessage}</p>
        {this.notificationsTable()}
      </div>
    );
  }
}

export default NotificationsPage;
