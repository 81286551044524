import React from 'react';
import API from './api_service';
import Validators from './helpers/Validators.js';
import './reset_pass.css';

class ForgotPass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      api: new API()
    };
  }

  submitEmail = async () => {
    if (!Validators.email.test(this.state.email)) {
      this.setState({secondaryErrorMessage: "Please enter a valid email."});
    } else {
      try {
        this.setState({secondaryErrorMessage: null, secondaryLoading: true});
        await this.state.api.LostPassword(this.state.email);
        this.setState({secondaryLoading: false, success: true});
      } catch (err) {
        this.setState({secondaryErrorMessage: "An error occurred. Please contact shop@guru.club if you need assistance.", secondaryLoading: false});
      }
    }
  }

  render() {
    var display;
    var submit;
    if (this.state.secondaryLoading) {
      submit = (<p className="reset">Submit</p>);
    } else if (this.state.success) {
      submit = (<p className="reset">Check your email for further instructions.</p>);
    } else {
      submit = (<a id="reset_submit" onClick={() => { this.submitEmail()}}>Submit</a>);
    }

    var errorMessage = this.state.secondaryErrorMessage ? (
      <p className="reset">{this.state.secondaryErrorMessage}</p>
    ) : null;

    display = (
      <>
        <p className="reset">Forgot Password</p>
        <p className="reset" style={{fontSize: '15px'}}>Enter the email associated with your account.</p>
        <input placeholder="user@example.com" className="reset" onChange={e=>this.setState({email: e.target.value})} value={this.state.email} />
        {errorMessage}
        {submit}
      </>
    );

    return (
      <div className="dark column centered align-center stretch-height stretch-width" style={{minWidth: "300px", minHeight: "500px"}}>
        {display}
      </div>
    );
  }
}

export default ForgotPass;
