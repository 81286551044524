import Service from '../api_service.js';
import SessionInventory from './Inventory.js';
import React from 'react';

export class Session {
  constructor(user, shop, token, extrefheader, inventory) {
    this.connected = !!user;
    this.user = user;
    this._token = token;
    this._extrefheader = extrefheader;
    this.api = new Service(user && user._id, token, extrefheader);
    if (user) {
      this.inventory = inventory || new SessionInventory(this.api); 
    }
    this.shop = shop;
    this.localImageSources = {};
    this.imageExists = {};
    this.authenticated = !!token;
    this.userWasHidden = user && user.hidden;
    this.applicationWasPending = user && user.appStatus === 'Pending';
  }

    // TODO @petermarathas fix this whole function.
  // if you're not @petermarathas, call him and tell him to fix this
  // 845-800-3036
  updating(info) {
    const infoKeys = Object.keys(info);
    const initValues = [
      ['user', 'user'],
      ['shop', 'shop'],
      ['token', '_token'],
      ['extrefheader', '_extrefheader'],
      ['inventory', 'inventory']
    ].map(([infoKey, thisKey]) => {
      return infoKeys.includes(infoKey) ? info[infoKey] : this[thisKey];
    })

    // initValues[0] is `user`
    if (initValues[0] && this.user) {
      initValues[0].appStatusChanged = this.user.appStatusChanged
        || (initValues[0].appStatus !== this.user.appStatus);
      initValues[0].didPublish = this.user.didPublish
        || (initValues[0].hidden === false && this.user.hidden === true);
      initValues[0].didActivate = this.user.didActivate
        || (initValues[0].active === false && this.user.active === true);
    }

    return new Session(...initValues);
  }

  get connectQueryParams() {
    return '?shop_id=' + this.shop._id + '&token=' + this._token;
  }

  /**
   * 
   * @param {Boolean} active 
   * @param {[String]} pages 
   */
  async withActive(active, pages) {
    await this.api.SetActive(active, pages);
    const newUser = { ...this.user, ...{active, scriptTagPages: pages}};
    return this.updating({user: newUser});
  }

  async updatingUser(update) {
    await this.api.UpdateProfile(update);
    const newUser = Object.assign(Object.assign({}, this.user), update);
    return this.updating({user: newUser});
  }

  async uploadImage(file, type) {
    var endpoint;
    switch (type) {
      case 'logo':
        endpoint = `${this.user._id}/profile-picture`;
        break;
      case 'banner':
        endpoint = `${this.user._id}/background-picture`;
        break;
      default:
        throw new Error('Invalid type sent to uploadImage');
    }
    await this.api.UploadFile(file, endpoint);
    this.localImageSources[type] = this.createObjectURL(file);
    this.imageExists[type] = true;
  }

  createObjectURL(file) {
    return (window.URL || window.webkitURL).createObjectURL(file);
  }

  async checkForImage(type) {
    var remoteUrl;
    if (this.imageExists[type] !== undefined) return this.imageExists[type];

    switch (type) {
      case 'logo':
        remoteUrl = cloudfrontUrl(this.user._id);
        break;
      case 'banner':
        remoteUrl = cloudfrontUrl(`${this.user._id}-background`);
        break;
      default:
        return null;
    }

    this.imageExists[type] = await this.api.CheckForPicture(remoteUrl);
    return this.imageExists[type];

  }

  imageSource(type) {
    if (this.localImageSources[type]) return this.localImageSources[type];
    if (type === 'banner') {
      return cloudfrontUrl(`${this.user._id}-background`);
    } else if (type === 'logo') {
      return cloudfrontUrl(this.user._id);
    } else return null;
  }

  errorLoadingImage(type) {
    delete this.localImageSources[type];
  }

}

function cloudfrontUrl(key) {
  return `https://dr2ucvwr1tnfi.cloudfront.net/${key}?invalidate=${new Date().getTime()}`;
}

export const SessionContext = React.createContext(new Session());
