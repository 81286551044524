import React from 'react';
import { Card, ResourceList, Page, FooterHelp, Link, Spinner } from '@shopify/polaris';
import { TitleBar } from '@shopify/app-bridge-react';
import DealListItem from './DealListItem';
import './Deals.css';
import Gallery from './components/Gallery/Gallery';
import { DefaultErrorBoundary } from '../services/bugsnag';

class Deals extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deals: {toTip: [], current: [], done: []}, loading: true, updatedDeals: [], showToast: false };
  }

  componentDidMount() {
    this.props.session.inventory.loadDeals().then((deals) => {
      this.setDeals(deals);
    }).catch(err => {
      this.setState({ loading: false, errorMessage: "Error loading."});
    });
  }

  setDeals = (deals) => {
    this.setState({deals: deals, loading: false, updatedDeals: []});
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
          <Spinner />
        </div>
      );
    } else {
      return (
        <>
          <Page>
            <TitleBar
              title="Sales"
            />
            {this.recentPosts()}
            {this.recentSales()}
            {this.allPosts()}
            <FooterHelp>
              Learn more about{' '}
              <Link external url={`https://guru.club/for-brands/brand-support/#ugc`}>
                user generated content.
              </Link>
            </FooterHelp>
          </Page>
        </>
      );
    }
  }

  recentPosts = () => {
    if (this.state.deals.toTip.length < 1) return null;
    var tipsSavedMarkup = this.state.showToast && (<p>
      Tips saved.
      </p>
    );
    return (
      <Card sectioned
        title="Recent Posts"
        >
          <p style={{marginBottom: '20px'}}>
            Your customers have made posts featuring your products. Tip them in store credit to increase their discounts in your store and encourage future posts.
          </p>
          <Gallery toTip={this.state.deals.toTip} setTip={async (deal,amount)=>{
              var { deals } = this.state;
              await this.props.session.api.SetTip(deal,amount)
              let idx = deals.toTip.findIndex(d=>d._id === deal);
              if (idx) {
                deals.toTip[idx].tip = amount;
              }
              
              this.setDeals(deals);
            }} />
          {tipsSavedMarkup}
      </ Card>
    )
  }

  recentSales = () => {
    if (this.state.deals.current.length < 1) return null;
    return (
      <Card sectioned
        title="Recent Sales"
        >
          <p style={{marginBottom: '20px'}}>
            You have recently sold products through guru.club. Once the user posts, you will be able to view their content here.
          </p>
          <DefaultErrorBoundary>
            <ResourceList
              resourceName={{singular: 'sale', plural: 'sales'}}
              items={this.state.deals.current}
              loading={this.state.loading}
              renderItem={DealListItem}
            />
          </DefaultErrorBoundary>
      </ Card>
    );
  }

  allPosts = () => {
    if (this.state.deals.done.length < 1) return null;
    return (
      <Card sectioned
        title="All Posts"
        >
          <p style={{marginBottom: '20px'}}>
            Browse user-generated content generated from your sales.
          </p>
          <DefaultErrorBoundary>
            <ResourceList
              resourceName={{singular: 'post', plural: 'posts'}}
              items={this.state.deals.done}
              loading={this.state.loading}
              renderItem={DealListItem}
            />
          </DefaultErrorBoundary>
      </ Card>
    )
  }


}


export default Deals;
