import React, {useState} from 'react';
import { Card, TextField, TextContainer, Form, FormLayout, Stack, Button, ButtonGroup, InlineError } from '@shopify/polaris';
import PropTypes from 'prop-types';
import './MaxRebate.css';

function MaxRebate({disabled, maxRebate: propsMaxRebate, updateMaxRebate}) {
    const [maxRebate, setMaxRebate] = useState(propsMaxRebate)
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);

    const disableActions = maxRebate === propsMaxRebate || isSaving || disabled;

    async function submitForm() {
        try {
            setIsSaving(true)
            await updateMaxRebate(maxRebate)
        } catch (err) {
            setSaveError(err.message);
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <Card
            sectioned
            title="Cashback Limit"
        >
            <Form onSubmit={submitForm}>
                <Stack spacing="loose" vertical>
                    <FormLayout>
                        <TextContainer>
                            This is the maximum cashback that can be offered for a single item. <span style={{fontWeight:'bold'}}>A rebate limit of $0 means "no limit".</span>
                        </TextContainer>
                        <div id="rebate-limit-wrapper">
                            <TextField
                                disabled={isSaving || disabled}
                                min={0}
                                type="number"
                                align="right"
                                prefix="$"
                                value={(maxRebate / 100).toFixed()}
                                onChange={e=>setMaxRebate(Math.round(e*100))}
                                onBlur={() => { if (isNaN(Number(maxRebate))) { setMaxRebate(propsMaxRebate) }}}
                            />
                        </div>
                        <Stack distribution="trailing" alignment="center">
                            <InlineError message={saveError} />
                            <ButtonGroup>
                                <Button plain onClick={()=>setMaxRebate(propsMaxRebate)} disabled={disableActions}>Cancel</Button>
                                <Button primary submit disabled={disableActions} loading={isSaving}>Save</Button>
                            </ButtonGroup>
                        </Stack>
                    </FormLayout>
                </Stack>
            </Form>
        </Card>
    )
}

MaxRebate.propTypes = {
    disabled: PropTypes.bool.isRequired,
    maxRebate: PropTypes.number.isRequired,
    updateMaxRebate: PropTypes.func.isRequired
}

export default MaxRebate;