import React, { useState } from 'react';
import banner from './banner';
import Validators from './helpers/Validators';
import { categories } from './helpers/Constants';
import Service from './api_service.js';
import encrypt from './jwefunctions.js';
import './Login.css';

const WINDOW_SIZES = {
  Login: {
    width: '300px',
    height: '357px',
  },
  Apply: {
    width: '300px',
    height: '999px'
  }
};

function LoginWindow() {
  const search = window.location.search.split('&');
  let shop_id  = search[0].startsWith('?shop_id=') && search[0].slice(9);
  let token    = search.length > 1 && search[1].startsWith('token=') && search[1].slice(6);
  let shop;
  try {
    shop = JSON.parse(window.localStorage.getItem('shop') || '{}');
  } catch (err) {
    shop = {};
  }

  const API = new Service(null, token, 'shopify:' + shop_id);

  const full_name = shop.shop_owner;
  let first_name = '';
  let last_name = '';
  if (full_name) {
    // let last_name be the last word in the string, first_name be a combo of all other words
    let names = full_name.split(' ');
    last_name = names.splice(-1, 1);
    last_name = (last_name || '') && last_name[0];
    first_name = (names.length || '') && names.join(' ');
  }

  const [mode, setMode] = useState('login');
  const [errorMessage, setErrorMessage] = useState('');
  let [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });
  let [applicationForm, setApplicationForm] = useState({
    company: shop.name || '',
    category: '',
    city: shop.city || '',
    first_name: first_name,
    last_name: last_name,
    password: '',
    confirm_password: '',
    email: (shop.customer_email || shop.email) || '',
    website: shop.domain || '',
  });


  function submitRow (primaryValue, primaryOnClick, secondaryValue, secondaryOnClick) {
    return (
      <div>
        <p id="fields-required" className={errorMessage && 'show'}>{errorMessage}</p>
        <div className="submit-row">
          <input type="submit" className="primary" value={primaryValue} onClick={primaryOnClick}/>
          <input type="submit" className="secondary" value={secondaryValue} onClick={secondaryOnClick} />
        </div>
      </div>
    )
  }

  function switchModes () {
    const nextMode = mode === 'login' ? 'apply' : 'login';
    setMode(nextMode);
  }

  async function login () {
    setErrorMessage('');
    try {
      const loginBody = {
        jwe: await encrypt(JSON.stringify({
        user: loginForm.email,
        password: loginForm.password,
        device: {
          name: 'Shopify Plugin'
        }
      }))
    };
      const response = await API.LinkShop('login', loginBody, shop_id);
      if (response.data.status === 'SUCCESS') {
        const { profile } = response.data.message;
        updateParent(profile);
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = function(errorMessage) {
        switch (errorMessage) {
          case "No token.":
            return "Email/password combo not found.";
          case "ConflictingMerchant":
            return "A user with this Shopify store already exists.";
          case "ConflictingShop":
            return "This user already has a Shopify store attached.";
          default:
            return "An unexpected error occurred. Please try again.";
        }
      }(err.message)
      setErrorMessage(errorMessage)
    }
  }

  async function apply() {
    setErrorMessage('');
    try {
      if (!Validators.instagram.test(applicationForm.instagram)) {
        throw new Error("InstagramException");
      }
      if (!Validators.email.test(applicationForm.email)) {
        throw new Error("EmailException");
      }
      if (!Validators.password.test(applicationForm.password)) {
        throw new Error("PasswordException");
      }
      if (applicationForm.password !== applicationForm.confirm_password) {
        throw new Error("SamePasswordException");
      }
      if (!Validators.exists.test(applicationForm.first_name) || !Validators.exists.test(applicationForm.last_name)) {
        throw new Error("NoNameException");
      }
      if (!Validators.exists.test(applicationForm.website)) {
        throw new Error("NoWebsiteException");
      }
      if (!Validators.category.test(applicationForm.category)) {
        throw new Error("CategoryException");
      }
      if (!Validators.phone.test(applicationForm.phone)) {
        throw new Error("PhoneException");
      }
      const encryptedPass = await encrypt(Buffer.from(applicationForm.password));
      const backendSafeInstagram = applicationForm.instagram.charAt(0) === '@' ? applicationForm.instagram.slice(1) : applicationForm.instagram;
      const applicationBody = {
        user: {
          isCreator: false,
          deviceArray: [{
            name: "Shopify Plugin"
          }],
          instagram: {
            username: backendSafeInstagram
          },
          contactInfo: {
            email: applicationForm.email,
            phone: applicationForm.phone
          },
          pass: encryptedPass,
          shopify: applicationForm.shop_id,
          referral: applicationForm.referral
        },
        portfolio: {
          isCreator: false,
          firstName: applicationForm.first_name,
          lastName: applicationForm.last_name,
          company: applicationForm.company,
          category: applicationForm.category,
          location: applicationForm.city,
          links: [{
            url: applicationForm.website
          }],
          url: applicationForm.website
        }
      };
      const response = await API.LinkShop('create', applicationBody, shop_id);
      if (response.data.status === 'SUCCESS') {
        if (response.data.message === 'Pending') // Application already exists.
        {
          setErrorMessage('This email has already submitted an application. Try logging in.');
        }
        else
        {
          let { profile } = response.data.message;
          profile.appStatus = 'Pending';
          updateParent(profile);
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = "An unknown error occurred. Please try again.";
      switch (err.message) {
        case "EmailException":
          errorMessage = "Please enter a valid email.";
          break;
        case "PasswordException":
          errorMessage = "Passwords must be 8-64 characters.";
          break;
        case "SamePasswordException":
          errorMessage = "Passwords do not match.";
          break;
        case "EmailAlreadyExistsError":
          errorMessage = "A user with this email already exists.";
          break;
        case "ConflictingMerchant":
          errorMessage = "An account already exists for this store.";
          break;
        case "CategoryException":
          errorMessage = "Please choose a valid genre for your shop.";
          break;
        case "NoNameException":
          errorMessage = "Please give your first and last name.";
          break;
        case "NoWebsiteException":
          errorMessage = "Please provide a website.";
          break;
        case "PhoneException":
          errorMessage = "Please provide us with a valid phone number at which we can reach you.";
          break;
        case "InstagramException":
          errorMessage = "Please enter a valid Instagram handle.";
          break;
        case "Pending":
          errorMessage = "This email has already submitted an application. Try logging in."
          break;
        default: break;
      }
      setErrorMessage(errorMessage);
    }
  }

  function handleInputChange (event) {
    const { name, value } = event.target;
    if (name.startsWith('login')) {
      setLoginForm({
        ...loginForm,
        [name.slice(6)]: value
      });
    } else {
      setApplicationForm({
        ...applicationForm,
        [name]: value
      });
    }
  }

  if (!shop_id || !token || window.opener === null) {
    return (<p className="stretch-width dark" style={{textAlign: 'center'}}>Invalid link. Please open this page from the Account page using the 'Connect' button.</p>);
  } else {
    const windowSize = mode === 'login' ? WINDOW_SIZES.Login : WINDOW_SIZES.Apply;
    const renderDiv = mode === 'login' ? (
      <div>
        <div className="form-field">
          <label>
            Email
          </label>
          <input type="text" autoComplete="username" value={loginForm.email} name="login_email" onChange={handleInputChange}/>
        </div>
        <div className="form-field">
          <label>
            Password
          </label>
          <input type="password" autoComplete="current-password" value={loginForm.password} name="login_password" onChange={handleInputChange} />
        </div>
        <a style={{textAlign: 'center', width: '100%', fontSize: '14px'}} href={window.location.origin + '/app/forgot_pass'}>Forgot your password?</a>
        { submitRow('Login', login, 'Create Account', switchModes) }
      </div>
    ) : (
        <div>
          <div className="form-row" style={{marginBottom: '-20px'}}>
            <div className="form-field name-field">
              <label>
                First Name *
              </label>
              <input type="text" value={applicationForm.first_name} name="first_name" onChange={handleInputChange}/>
            </div>
            <div className="form-field name-field">
              <label>
                Last Name *
              </label>
              <input type="text" name="last_name" value={applicationForm.last_name} onChange={handleInputChange}/>
            </div>
          </div>
          <div className="form-field">
            <label>
              Email *
            </label>
            <input type="text" autoComplete="username" name="email" value={applicationForm.email} onChange={handleInputChange}/>
          </div>
          <div className="form-field">
            <label>
              Direct Phone Number *
            </label>
            <input type="text" autoComplete="phone" name="phone" value={applicationForm.phone} onChange={handleInputChange} />
          </div>
          <div className="form-row" style={{marginTop: '-20px', marginBottom: '-20px'}}>
            <div className="form-field name-field">
              <label>
                Password *
              </label>
              <input type="password" value={applicationForm.password} name="password" autoComplete="new-password" onChange={handleInputChange}/>
            </div>
            <div className="form-field name-field">
              <label>
                Confirm Password *
              </label>
              <input type="password" value={applicationForm.confirm_password} name="confirm_password" autoComplete="new-password" onChange={handleInputChange}/>
            </div>
          </div>
          <div className="form-field">
            <label>
              Shop Display Name *
            </label>
            <input type="text" name="company" value={applicationForm.company} onChange={handleInputChange}/>
          </div>
          <div className="form-field">
            <label>
              Shop Genre *
            </label>
            <select name="category" value={applicationForm.category} onChange={handleInputChange}>
              <option value=''></option>
              {categories.map(category => (<option key={category} value={category}>{category}</option>))}
            </select>
          </div>
          <div className="form-field">
            <label>
              Website *
            </label>
            <input type="text" name="website" value={applicationForm.website} onChange={handleInputChange}/>
          </div>
          <div className="form-field">
            <label>
              Store Instagram *
            </label>
            <input type="text" value={applicationForm.instagram} name="instagram" onChange={handleInputChange}/>
          </div>
          <div className="form-field">
            <label>
              How did you hear about us?
            </label>
            <input type="text" value={applicationForm.referral} name="referral" onChange={handleInputChange}/>
          </div>
          <div className="form-field">
            <label>
              Shop City of Origin
            </label>
            <input type="text" name="city" value={applicationForm.city} onChange={handleInputChange}/>
          </div>
          { submitRow('Apply', apply, 'I have an account.', switchModes) }
        </div>
      );
      return (
        <div className="login-container dark" style={windowSize}>
          <div style={windowSize}>
            <form className="column align-center" onSubmit={(event) => event.preventDefault()}>
              { banner }
              { renderDiv }
            </form>
          </div>
        </div>
      );
  }
}

function updateParent(user) {
  if (window.opener === null) {
    window.close();
  } else {
    window.addEventListener("message", function receive(event) {
      console.log(event);
      if (event.origin === window.location.origin && event.data === 'User received.') {
        window.close();
      }
    });

    window.opener.postMessage(JSON.stringify({user}), window.location.origin);
  }
}

export default LoginWindow;
