import React, { useState } from 'react';
import { Card, TextField, Layout } from '@shopify/polaris';
import Validators from '../helpers/Validators';

function AccountSettings({email, handle, updateSession, session}) {

    const [inputEmail, setInputEmail] = useState(email)
    const [inputHandle, setInputHandle] = useState(handle)

    const [isEditingEmail, setIsEditingEmail] = useState(false)
    const [isEditingHandle, setIsEditingHandle] = useState(false)

    const [isSavingEmail, setIsSavingEmail] = useState(false)
    const [isSavingHandle, setIsSavingHandle] = useState(false)

    const [emailError, setEmailError] = useState(null)
    const [handleError, setHandleError] = useState(null)

    let secondaryEmailActions = []
    let primaryEmailAction

    let secondaryHandleActions = []
    let primaryHandleAction

    function saveEmail() {
        if (!Validators.email.test(inputEmail)) {
            setEmailError("Please provide a valid email addres.")
        } else {
            setIsSavingEmail(true)
            setEmailError(null)
            session.api.UpdateProfile({ email: inputEmail })
                .then(() => {
                    const user = session.user
                    user.email = inputEmail
                    updateSession({user})
                    setIsEditingEmail(false)
                })
                .catch((err) => {
                    switch (err.message) {
                        case "EmailAlreadyExistsError":
                            setEmailError("This email is already in use by another merchant.")
                            break;
                        default:
                            setEmailError("An unexpected error occurred. Please double check the Instagram handle you entered.")
                            break;
                    }
                })
                .finally(() => setIsSavingEmail(false))
        }
    }

    function saveHandle() {
        if (!Validators.instagram.test(inputHandle)) {
            setHandleError("Please provide a valid Instagram handle.")
        } else {
            setIsSavingHandle(true)
            setHandleError(null)
            session.api.UpdateProfile({ instagram: inputHandle })
                .then(() => {
                    const user = session.user
                    user.minstagram.username = inputHandle
                    updateSession({user})
                    setIsEditingHandle(false)
                })
                .catch((err) => {
                    switch (err.message) {
                        case "DuplicateInstagramHandle":
                            setHandleError("This handle is already in use by another merchant.")
                            break;
                        default:
                            setHandleError("An unexpected error occurred. Please double check the Instagram handle you entered.")
                            break;
                    }
                })
                .finally(() => setIsSavingHandle(false))
        }
    }

    if (isEditingEmail) {
        primaryEmailAction = {
            content: 'Save',
            loading: isSavingEmail,
            onAction: saveEmail
        }
        secondaryEmailActions = [{
            content: 'Cancel',
            onAction: () => { setInputEmail(email); setIsEditingEmail(false) }
        }]
    } else {
        secondaryEmailActions = [{
            content: 'Edit',
            onAction: () => setIsEditingEmail(true)
        }]
    }

    if (isEditingHandle) {
        primaryHandleAction = {
            content: 'Save',
            loading: isSavingHandle,
            onClick: saveHandle
        }
        secondaryHandleActions = [{
            content: 'Cancel',
            onAction: () => { setInputHandle(handle); setIsEditingHandle(false) }
        }]
    } else {
        secondaryHandleActions = [{
            content: 'Edit',
            onAction: () => setIsEditingHandle(true)
        }]
    }

    return (
    <Layout.AnnotatedSection
        title="Account"
    >
        <Card
            sectioned
            title="Email"
            secondaryFooterActions={secondaryEmailActions}
            primaryFooterAction={primaryEmailAction}
        >
            <p style={{marginBottom: '10px'}} >The address by which we can reach you.</p>
            <TextField type="email" value={inputEmail} error={emailError} disabled={!isEditingEmail} onChange={setInputEmail} />
        </Card>
        <Card
            sectioned
            title="Instagram Handle"
            secondaryFooterActions={secondaryHandleActions}
            primaryFooterAction={primaryHandleAction}
        >
            <p style={{marginBottom: '10px'}} >This is the handle that your shoppers will tag in exchange for cashback rewards.</p>
            <TextField type="email" value={inputHandle} error={handleError} prefix="@" disabled={!isEditingHandle} onChange={setInputHandle} />
        </Card>
    </Layout.AnnotatedSection>
    )
}

export default AccountSettings;