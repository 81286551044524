import React from 'react';
import { Redirect } from 'react-router-dom';

class UsageCharges extends React.Component {

  state = {
    usagecharges: [],
    currentPage: 0,
    pages: 0,
    total: 0,
    backPath: [],
    loading: true
  }

  async componentDidMount() {
    this.loadUsageCharges();
  }

  infoHeader = (state) => {
    const { currentPage, total, pages, usagecharges, loading, backPath } = state;

    const indexStart = Math.max(0, (currentPage - 1) * 50 + 1);
    const indexEnd   = Math.max(0, indexStart + usagecharges.length - 1);
    const totalInfo  = <p>{indexStart} to {indexEnd} of {total}</p>;
    const pageInfo   = <p>Page {currentPage} of {pages}</p>;
    const backDisabled = backPath.length <= 0 || loading ? 'disabled' : null;
    const nextDisabled = indexEnd >= total || loading ? 'disabled' : null;

    const back = <button disabled={backDisabled} onClick={this.backPage}>Back</button>;
    const next = <button disabled={nextDisabled} onClick={this.nextPage}>Next</button>;

    return (
      <div className="row space-around stretch-width align-center">
        {back}
        {pageInfo}
        {totalInfo}
        {next}
      </div>
    );
  }

  backPage = async () => {
    this.state.backPath.pop();
    let lastId;
    if (this.state.backPath.length)
      lastId = this.state.backPath[this.state.backPath.length - 1];

    await this.loadUsageCharges(lastId);
  }

  nextPage = async () => {
    console.log(this.state);
    let { lastId } = this.state;
    if (lastId)
      this.state.backPath.push(lastId);

    await this.loadUsageCharges(lastId);
  }

  loadUsageCharges = async (cutoffId) => {
    try {
      this.setState({loading: true});

      const { data: { message: res } } = await this.props.api.UsageCharges(cutoffId);

      const { results, total, currentPage, pages, lastId: nextId } = res;
      this.setState({
        usagecharges: results,
        total: total,
        currentPage: currentPage,
        pages: pages,
        lastId: nextId
      });
    } catch (err) {

    }

    this.setState({loading: false})
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    const displayMessage = m => (
      <p className="admin stretch-width" style={{textAlign: 'center'}}>
        {m}
      </p>
    );
    if (this.state.loading) {
      return displayMessage("Loading...");
    } else if (this.state.usagecharges.length === 0) {
      return displayMessage("No charges.");
    } else {
      return (
        <div className="stretch-width column align-center admin">
          {this.infoHeader(this.state)}
          <table className="contain-width">
            <thead>
              <tr>
                <th>Created</th>
                <th>Reference ID</th>
                <th>User</th>
                <th>Total Due</th>
                <th>Total Charged</th>
                <th>Deals</th>
              </tr>
            </thead>
            <tbody>
              {this.state.usagecharges.map(usagecharge => {
                return (
                  <tr key={usagecharge._id}>
                    <td>{global.shorten(usagecharge.createdOn)}</td>
                    <td>{usagecharge.id}</td>
                    <td onClick={e => {
                      this.setState({redirect: "/app/admin/console/profiles/" + usagecharge.user._id});
                    }}><a>{usagecharge.user.company}</a></td>
                    <td>${global.toUSD(usagecharge.totalPaymentDue)}</td>
                    <td>${global.toUSD(usagecharge.charged)}</td>
                    <td>{usagecharge.deals.length}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default UsageCharges;
