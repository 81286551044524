import { NavLink } from "react-router-dom";
import React, { Component } from 'react';

class NavMenuLink extends Component {

  render() {
    return (
        <label>
          <NavLink className={this.props.className} activeClassName="active-link" to={this.props.destination}>
            {this.props.title}
          </NavLink>
        </label>
    )
  }
}

export default NavMenuLink;
