import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import React from 'react'
import MessageDisplay from '../modules/messagedisplay/MessageDisplay'


export const bugsnagClient = bugsnag({
    apiKey: '43e1552a2b0d3d5d9006a1a89e3a001f',
    appVersion: require('../../package.json').version,
    beforeSend: function (report) {
        try {
        var user    = sessionStorage.getItem('user');
        user        = user && JSON.parse(user);
        const id    = user && user._id;
        
        let name = sessionStorage.getItem('shop');
        name = name && JSON.parse(name);

        report.app.releaseStage = (/^(https:\/\/\w+\.ngrok)|(http:\/\/localhost)/.test(window.location.href)) ?
            'development' : 'production';
        if (report.app.releaseStage === 'development') {
            // don't report development errors
            return false;
        }

        report.user = {
            id: id,
            name: name
        };
        } catch (err) {
        console.error(err)
        }
    }
});
  
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');
  
export function DefaultErrorBoundary(props) {
    return (<ErrorBoundary FallbackComponent={()=><MessageDisplay message="Sorry, an unexpected error occurred." />}>{props.children}</ErrorBoundary>)
}
