import React from 'react';
import { Redirect } from 'react-router-dom';

class Deals extends React.Component {

  state = {
    deals: [],
    currentPage: 0,
    pages: 0,
    total: 0,
    backPath: [],
    loading: true
  }

  componentDidMount() {
    this.loadDeals();
  }

  infoHeader = (state) => {
    const { currentPage, total, pages, deals, loading, backPath } = state;

    const indexStart = Math.max(0, (currentPage - 1) * 50 + 1);
    const indexEnd   = Math.max(0, indexStart + deals.length - 1);
    const totalInfo  = <p>{indexStart} to {indexEnd} of {total}</p>;
    const pageInfo   = <p>Page {currentPage} of {pages}</p>;
    const backDisabled = backPath.length <= 0 || loading ? 'disabled' : null;
    const nextDisabled = indexEnd >= total || loading ? 'disabled' : null;

    const back = <button disabled={backDisabled} onClick={this.backPage}>Back</button>;
    const next = <button disabled={nextDisabled} onClick={this.nextPage}>Next</button>;

    return (
      <div className="row space-around stretch-width align-center">
        {back}
        {pageInfo}
        {totalInfo}
        {next}
      </div>
    );
  }

  backPage = async () => {
    this.state.backPath.pop();
    let lastId;
    if (this.state.backPath.length)
      lastId = this.state.backPath[this.state.backPath.length - 1];

    await this.loadDeals(lastId);
  }

  nextPage = async () => {
    console.log(this.state);
    let { lastId } = this.state;
    if (lastId)
      this.state.backPath.push(lastId);

    await this.loadDeals(lastId);
  }

  loadDeals = async (cutoffId) => {
    try {
      this.setState({loading: true});

      const { data: { message: res } } = await this.props.api.Deals(cutoffId);

      const { results, total, currentPage, pages, lastId: nextId } = res;
      this.setState({
        deals: results,
        total: total,
        currentPage: currentPage,
        pages: pages,
        lastId: nextId
      });
    } catch (err) {

    }

    this.setState({loading: false})
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={"/app/admin/console/deals/" + this.state.redirect} />
    }
    const displayMessage = m => (
      <p className="admin stretch-width" style={{textAlign: 'center'}}>
        {m}
      </p>
    );
    if (this.state.loading) {
      return displayMessage("Loading...");
    } else if (this.state.deals.length === 0) {
      return displayMessage("No deals.");
    } else {
      return (
        <div className="stretch-width column align-center admin">
          {this.infoHeader(this.state)}
          <table className="contain-width">
            <thead>
              <tr>
                <th>Created</th>
                <th>Status</th>
                <th>Guru</th>
                <th>Brand</th>
                <th>Item</th>
              </tr>
            </thead>
            <tbody>
              {this.state.deals.map(deal => {
                let status = global.statusOf(deal);

                let { firstName, lastName } = deal.influencer;

                let name;
                if (firstName || lastName)
                  name = (firstName || "") + " " + (lastName || "");
                else
                  name = deal.influencer.igHandle;

                return (
                  <tr className="clickable" key={deal._id} onClick={e=>this.goTo(deal)}>
                    <td>{global.shorten(deal.requested)}</td>
                    <td>{status}</td>
                    <td>{name}</td>
                    <td>{deal.brand.company}</td>
                    <td>{deal.productName}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }

  goTo = (deal) => {
    store(deal);
    this.setState({redirect: deal._id});
  }
}

function store(deal) {
  let deals = window.sessionStorage.getItem('deals');
  deals = JSON.parse(deals || '{}');

  deals[deal._id] = deal;

  window.sessionStorage.setItem('deals', JSON.stringify(deals));
}


export default Deals;
