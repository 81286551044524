import React from 'react';
import { Redirect } from 'react-router-dom';
import { categories } from '../helpers/Constants';

const imageFileTypeReq = /^(image\/png|image\/jpg|image\/jpeg)$/i;

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: props.match.params.id
    }
  }

  async componentWillMount() {
    try {
      const res = await this.props.api.GetProfile(this.props.match.params.id);
      const profile = res.data.message;
      this.setState({profile: profile, category: profile.category});

      if (profile.isCreator) {
        const rebates = await this.props.api.Ledger(profile._id);
        this.setState({rebates: rebates});
      } else {
        const products = await this.props.api.AdminProducts(profile._id);
        this.setState({products: products});
      }
    } catch (err) {
    }
  }

  setFreeTrial = async (active) => {
    try {
      this.setState({loading: true});
      const update = { exemptFromCharge: active };
      await this.props.api.AdminUpdateProfile(this.state.user, update);

      const { profile } = this.state;
      profile.chargeStatus = active ? 'Free Trial' : 'Free trial revoked.';

      this.setState({profile});
    } catch (err) {
      window.alert(err.message);
    } finally {
      this.setState({loading: false});
    }
  }

  updateCategory = async () => {
    this.setState({loading: true});
    try {
      const { category } = this.state;
      await this.props.api.AdminUpdateProfile(this.state.user, {
        category: category
      });
      const { profile } = this.state;
      profile.category = category;
      this.setState({profile: profile});
    } catch (err) { }
    this.setState({loading: false});
  }

  publish = async () => {
    this.setState({loading: true});
    try {
      await this.props.api.AdminUpdateProfile(this.state.user, {
        hidden: false
      });
      const { profile } = this.state;
      profile.hidden = false;
      this.setState({profile: profile});
    } catch (err) {
    }
    this.setState({loading: false});
  }

  hide = async () => {
    this.setState({loading: true});
    try {
      await this.props.api.AdminUpdateProfile(this.state.user, {
        hidden: true
      });
      const { profile } = this.state;
      profile.hidden = true;
      this.setState({profile: profile});
    } catch (err) {}
    this.setState({loading: false});
  }

  delete = async () => {
    if (!window.confirm("Delete " + this.state.profile.company + "?")) {
      return;
    }

    this.setState({loading: true});
    try {
      await this.props.api.AdminDeleteProfile(this.state.user);
      this.setState({goBack: true});
    } catch (err) { }
    this.setState({loading: false});
  }

  reject = async () => {
    this.setState({loading: true});
    try {
      await this.props.api.RespondToApplication(this.state.user, 'reject');
      const { profile } = this.state;
      profile.appStatus = "Denied";
      this.setState({profile: profile});
    } catch (err) {
    }
    this.setState({loading: false});

  }

  approve = async () => {
    this.setState({loading: true});
    try {
      await this.props.api.RespondToApplication(this.state.user, 'accept');
      const { profile } = this.state;
      profile.appStatus = "Approved";
      this.setState({profile: profile});
    } catch (err) {
    }
    this.setState({loading: false});
  }

  render() {
    if (this.state.goBack) {
     return <Redirect to="/app/admin/console" />;
    }

    var banner;
    if (this.state.profile && !this.state.profile.isCreator) {
      banner = (
        <div className="stretch-width row centered" style={{margin: '10px 0 10px 0'}}>
          <ImageDropbox imgHeight="225px" imgWidth="281px" type="Banner"
            validateImage={(image) => {
              if (image.width <= image.height) {
                throw new Error("Banner must be a landscape image.");
              }
            }}
            imageReady={()=>{this.setState({bannerReady: true})}}
            imageSaved={()=>{this.setState({bannerReady: null})}}
            user={this.state.user}
            src={()=>{return 'https://dr2ucvwr1tnfi.cloudfront.net/' + this.state.user + '-background?invalidate=' + new Date().getTime()}}
            api={this.props.api}
          />
        </div>
      );
    }

    var logo = (
      <div>
        <ImageDropbox imgHeight="150px" imgWidth="150px" type="Logo"
          validateImage={(image)=>{
            if (image.width !== image.height) {
              throw new Error("Logo must be a square image.");
            }
          }}
          imageReady={()=>{this.setState({logoReady: true})}}
          imageSaved={()=>{this.setState({logoReady: null})}}
          user={this.state.user}
          src={()=>{return 'https://dr2ucvwr1tnfi.cloudfront.net/' + this.state.user + '?invalidate=' + new Date().getTime()}}
          api={this.props.api}
        />
      </div>
    );

    var profileBox;
    var statusBox;
    if (this.state.profile) {
      const { profile } = this.state;

      var categoryBox;
      let referralText;
      if (!profile.isCreator) {
        // category selector

        // for update category buttons..
        // disable if loading, or if chosen category is the same as set category
        // thus nothing to "update" or "cancel"
        var buttonsDisabled = this.state.category === profile.category || this.state.loading;
        categoryBox = (
          <div className="row">
            <select name="category" disabled={this.state.loading && "disabled"} value={this.state.category} onChange={(e)=>{this.setState({category: e.target.value})}}>
              {categories.map(category=>(<option key={category} value={category}>{category}</option>))}
            </select>
            <button disabled={buttonsDisabled && "disabled"} onClick={this.updateCategory}>Update</button>
            <button disabled={buttonsDisabled && "disabled"} onClick={e=>this.setState({category:profile.category})}>Cancel</button>
          </div>
        );
        referralText = <p>Referred by "{profile.referral}"</p>;
      }

      profileBox = (<div style={{maxWidth: '300px'}} className="column space-between">
          <h1>{profile.isCreator ? profile.name : profile.company}</h1>
          <p>{profile.minstagram && profile.minstagram.username}</p>
          <p>{profile.email}</p>
          <p>{profile.phone}</p>
          {referralText}
          {categoryBox}
        </div>
      );

      if (!profile.isCreator) {
        var status;
        var button1;
        var button2;
        const genButton = (onClick, className, text) => {
          return (<button disabled={this.state.loading && "disabled"} onClick={onClick} className={className}>{text}</button>);
        };
        if (profile.appStatus === "Denied") {
          status = "This store has been rejected.";
          button1 = genButton(this.approve, null, 'Undo');
          button2 = genButton(this.delete, "admin reject", "Delete");
        } else {
          button2 = genButton(this.reject, "admin reject", "Reject");
          if (profile.appStatus === "Pending") {
            status = "This store is awaiting approval.";
            button1 = genButton(this.approve, "highlighted", "Approve");
          } else if (profile.appStatus === "Approved") {
            if (profile.hidden) {
              status = "This store is hidden.";
              button1 = genButton(this.publish, null, "Publish");
            } else {
              status = "This store is live.";
              button1 = genButton(this.hide, null, "Hide");
            }
          }
        }

        const chargeStatus = ((chargeStatus) => {
          switch (chargeStatus) {
            case 'Free Trial':
              return <p>{chargeStatus}</p>;
            case 'Activated':
              return <p className='success'>{chargeStatus}</p>;
            case 'Pending':
              return <p className='admin highlighted'>{chargeStatus}</p>;
            case 'Free trial revoked.':
              return <p className="admin reject">{chargeStatus}</p>;
            default:
              return null;
          } 
        })(profile.chargeStatus);
        const chargeStatusButton = profile.chargeStatus === 'Free Trial' ?
          <button onClick={e=>this.setFreeTrial(false)} className="admin reject">Revoke Free Trial</button>
          :
          <button onClick={e=>this.setFreeTrial(true)}>Start Free Trial</button>;
        if (status) {
          statusBox = (
            <div className="column space-between">
              <p style={{textAlign: 'center'}}>{status}</p>
              <div className="row centered">
                {button1}
                {button2}
              </div>
              <p style={{margin: '30px 0px -10px 0px'}}>Charge Status</p>
              {chargeStatus}
              {chargeStatusButton}
            </div>
          );
        }
      }
    }

    return (
      <div className="dark admin stretch-width" style={{background: '#191919', minWidth: '585px'}}>
        <div className="contain-width column align-start">
          <button onClick={()=>{this.props.history.goBack()}}>Back</button>
          {banner}
          <div className="row align-start flex-wrap space-between stretch-width">
            {logo}
            {profileBox}
            {statusBox}
          </div>
          { this.state.products ? this.productsTable(this.state.products) : null }
          { this.state.rebates ? this.rebatesTable(this.state.rebates) : null }
        </div>
      </div>
    );
  }

  setProductSuspended = async (product_id, suspended) => {
    this.setState({loading: true});
    try {
      await this.props.api.SetProductSuspended(product_id, suspended);
      var { products } = this.state;
      var idx = products.findIndex(p => p._id === product_id);
      if (idx >= 0) {
        products[idx].isSuspended = suspended;
      }
      this.setState({products: products});
    } catch (err) { }
    this.setState({loading: false});
  }

  rebatesTable = (rebates) => {
    if (rebates.length === 0) {
      return <p className="stretch-width">Guru has no rebates.</p>;
    }

    const row = (rebate) => {
      const { 
        refundableNow,
        reusable,
        processingFee,
        createdOn,
        sales_tax,
        refunds,
        usage,
        deposited
      } = rebate;

      const getAmounts = (items) => items.reduce((a,c)=>a+c.amount,0);
      const dollars = (pennies) => "$" + global.toUSD(pennies);

      return (
        <tr key={rebate._id}>
          <td>{global.shorten(createdOn)}</td>
          <td>{dollars(deposited)}</td>
          <td>{dollars(getAmounts(usage.filter(u=>u.ongoing)))}</td>
          <td>{dollars(getAmounts(usage.filter(u=>u.forfeited)))}</td>
          <td>{dollars(getAmounts(refunds))}</td>
          <td>{dollars(getAmounts(sales_tax))}</td>
          <td>{dollars(processingFee)}</td>
          <td>{dollars(refundableNow)}</td>
          <td>{dollars(reusable)}</td>
          <td></td>
        </tr>
      )
    }

    console.log(rebates);

    return (
      <div className="column stretch-width align-center">
        <h5>Rebates</h5>
        <table className="contain-width">
          <thead>
            <tr>
              <th>Date</th>
              <th>Deposited</th>
              <th>Ongoing</th>
              <th>Forfeited</th>
              <th>Refunded</th>
              <th>Sales Tax</th>
              <th>Fee</th>
              <th>Refundable</th>
              <th>Reusable</th>
            </tr>
          </thead>
          <tbody>
            { rebates.map(row) }
          </tbody>
        </table>
      </div>
    )
  }

  productsTable = (products) => {
    if (products.length === 0) {
      return (<p className="stretch-width">This shop has no listed products.</p>);
    }

    return (
      <div id="products_table" className="stretch-width column align-center">
        <h5>Products</h5>
        <table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Status</td>
              <td>Inventory</td>
              <td>Version Support</td>
              <td className={"sold"}>Sold</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              var status;
              if (product.isSuspended) {
                status = (<p style={{color:'red'}}>Removed by admin.</p>);
              } else if (product.status === 'Available') {
                status = (<p className="success">Available</p>);
              } else if (product.status === 'Error') {
                status = (<p>Error: {product.errorList}</p>);
              } else { status = (<p>{product.status}</p>); }

              var disabled = this.state.loading ? "disabled" : null;
              var action = product.isSuspended ? (
                <button disabled={disabled}
                  onClick={() => {this.setProductSuspended(product._id, false);}}>
                  Make Available
                </button>
              ) : (
                <button disabled={disabled} className="admin reject"
                  onClick={() => {this.setProductSuspended(product._id, true);}}>
                  Remove
                </button>
              );

              const soldClass = (product.sold > 0 && 'sold') || null;
              const versionSupport = apiVersion(product);
              return (
                <tr key={product._id}>
                  <td>{product.name}</td>
                  <td>{status}</td>
                  <td>{product.inStock}</td>
                  <td>{versionSupport}</td>
                  <td className={soldClass}>{product.sold}</td>
                  <td>{action}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

function apiVersion(product) {
  switch (product.version_support) {
    case '2019-11':
      return '1.3.8.0'; // convert API version to more available app version
    default:
      return 'All';
  }
}

class ImageDropbox extends React.Component {

  state = {};
  input = React.createRef();

  choose = () => {
    try {
      this.setState({error: null, src: null, ready: null, success: null});
      const file = this.input.current.files[0];
      if (!imageFileTypeReq.test(file.type)) {
        this.setState({error: "Image must be of type png or jp(e)g"});
      } else {
        var image = new Image();
        var src = (window.URL || window.webkitURL).createObjectURL(file);
        image.onload = () => {
          try {
            this.props.validateImage && this.props.validateImage(image);
            this.props.imageReady && this.props.imageReady();
            this.setState({src: src, ready: true});
          } catch (err) {
            this.setState({error: err.message});
          }
        }
        image.src = src;
      }
    } catch (err) {
      this.setState({error: err.message});
    }
  }

  upload = async () => {
    this.setState({uploading: true});
    try {
      await this.props.api.UploadImage(this.input.current.files[0], this.props.user, this.props.type.toLowerCase());
      this.setState({success: true, ready: false});
      this.props.imageSaved();
    } catch (err) {
      this.setState({error: err.message});
    }
    this.setState({uploading: false});
  }

  render() {
    var imgStyle = {width: this.props.imgWidth, height: this.props.imgHeight};
    var imgSrc = this.state.src || this.props.src();

    var statusText;
    if (this.state.error) {
      statusText = (<p>{this.state.error}</p>);
    } else if (this.state.uploading) {
      statusText = (<p>Uploading..</p>);
    } else if (this.state.ready) {
      statusText = (<button className="highlighted" onClick={this.upload}>Click here to upload</button>);
    } else if (this.state.success) {
      statusText = (<p className="success">{this.props.type} uploaded.</p>);
    } else {
      statusText = (<p>{this.props.type}</p>);
    }


    return (
      <div className="row aligned-end">
        <img style={imgStyle} src={imgSrc} alt={this.props.type}/>
        <div className="column space-between stretch-height" style={{marginLeft: '10px'}}>
          {statusText}
          <input type="file" ref={this.input} onChange={this.choose} />
        </div>
      </div>
    );
  }
}

export default Profile;
