import React from 'react';
import { Redirect, NavLink, Route, Switch } from 'react-router-dom';
import ProfilesPage from './Profiles';
import Profile from './Profile';
import NotificationsPage from './Notifications';
import UsageCharges from './UsageCharges';
import Deals from './Deals';
import Deal from './Deal';
import KPIs from './KPIs'

class Console extends React.Component {

  render() {
    const renderProfiles = (p) => <ProfilesPage api={this.props.api} {...p} />;
    const renderNotis = (p)=><NotificationsPage api={this.props.api} {...p} />;
    const renderUCs = p => <UsageCharges api={this.props.api} {...p} />;
    const renderDeals = p=><Deals api={this.props.api} {...p} />;
    const renderDeal = p=><Deal api={this.props.api} {...p} />;
    const renderKpis = p=><KPIs api={this.props.api} {...p} />;
    return (
      <div className="admin dark contain-width">
        <div className="row stretch-width">
          <NavLink exact className="admin tab" activeClassName="selected" to={"/app/admin/console/profiles"}>Profiles</NavLink>
          <NavLink exact className="admin tab" activeClassName="selected" to={"/app/admin/console/kpis"}>KPIs</NavLink>
          <NavLink className="admin tab" activeClassName="selected" to={"/app/admin/console/notifications"}>Notifications</NavLink>
          <NavLink exact className="admin tab" activeClassName="selected" to={"/app/admin/console/usagecharges"}>Usage Charges</NavLink>
          <NavLink className="admin tab" activeClassName="selected" to={"/app/admin/console/deals"}>Deals</NavLink>
          <NavLink exact className="admin tab" activeClassName="selected" to={"/app/admin/console/checkouts"}>Checkouts</NavLink>
        </div>
        <Switch>
          <Route exact path="/app/admin/console/profiles" render={renderProfiles}/>
          <Route path="/app/admin/console/profiles/:id" render={(p)=><Profile api={this.props.api} {...p} />}/>
          <Route path="/app/admin/console/notifications" render={renderNotis}/>
          <Route path="/app/admin/console/kpis" render={renderKpis}/>
          <Route path="/app/admin/console/usagecharges" render={renderUCs} />
          <Route path="/app/admin/console/deals" exact render={renderDeals} />
          <Route path="/app/admin/console/deals/:id" render={renderDeal} />
          <Redirect to="/app/admin/console/profiles" />
        </Switch>
      </div>
    );
  }
}

global.statusOf = (deal) => {
  switch (deal.status) {
    case "Requested":
      return <p>Expires on {global.shorten(deal.expires)}</p>;
    case "Pending":
      return <p className="admin highlighted">Linked</p>;
    case "Expired":
      return <p className="admin reject">Expired</p>;
    case "Done":
      return <p className="success">Complete</p>;
    default:
      return <p>Invalid.</p>
  }
}

global.shorten = (date) => {
  if (!date) return null;

  if (typeof date == "string") date = new Date(date);

  return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
}


global.toUSD = (pennies) => {
  return (Math.round(pennies) / 100.0).toFixed(2);
}

export default Console;
