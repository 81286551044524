import React from 'react';

class KPIsPage extends React.Component {

    constructor(props) {
        super(props)
    }

  state = {
    kpis: [],
    loading: true,
    message: '',
    sending: false
  }

  async componentDidMount() {
      try {
        const kpis = await this.props.api.GetKPIs();
        this.setState({loading: false, kpis});
      } catch (err) { }
  }

  updateMessage = (e) => {
    this.setState({message: e.target.value});
  }

  sendMessage = async () => {
    const { message } = this.state;
    if (!message) {
      window.alert("Cannot send a notification without a message.");
    } else if (window.confirm("Send notification?")) {
      try {
        this.setState({sending: true, errorMessage: null});
        const noti = await this.props.api.SendNotification(message);
        var { notifications } = this.state;
        notifications.splice(0, 0, noti);
        this.setState({sending: false, message: '', notifications: notifications});
      } catch (err) {
        this.setState({sending: false, errorMessage: err.message});
      }
    }
  }

  notificationsTable = () => {
    if (this.state.loading) {
      return (
        <p className="stretch-width" style={{textAlign: 'center'}}>Loading...</p>
      );
    } else if (this.state.kpis.length === 0) {
      return (
        <p className="stretch-width" style={{textAlign: 'center'}}>No notifications.</p>
      );
    } else {
      return (
        <table className="stretch-width">
          <thead>
            <tr>
              <th>Date</th>
              <th>Gurus</th>
              <th>Brands</th>
              <th>Deals Started</th>
              <th>Deals Completed</th>
            </tr>
          </thead>
          <tbody>
            {this.state.kpis.map((noti) => {
              return (
                <tr key={noti._id}>
                  <td>{noti.date}</td>
                  <td>{noti.gurus||0}</td>
                  <td>{noti.brands||0}</td>
                  <td>{noti.dealsCreated||0}</td>
                  <td>{noti.dealsClosed||0}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  render() {
    return (
      <div className="contain-width">
        <p className="stretch-width" style={{textAlign: 'center'}}>{this.state.errorMessage}</p>
        {this.notificationsTable()}
      </div>
    );
  }
}

export default KPIsPage;
