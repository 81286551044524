import React, { useState } from 'react';
import {  Card, TextField, RangeSlider, Form, FormLayout, Stack, Button, ButtonGroup, InlineError } from '@shopify/polaris';
import './RebateSetter.css';
import PropTypes from 'prop-types';

function RebateSetter({rebate: rebateProp, updateRebate, disabled}) {

    const initialRebate = Math.round( 100 * rebateProp );
    const [rebate, setRebate] = useState(initialRebate);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);

    
    // update text field & range slider fn
    function updateValue(string) {
        if (string === "") {
            setRebate(0);
        } else {
            const num = Number(string);
            if (!isNaN(num) && num >= 0 && num <= 100) {
                setRebate(Math.round(num))
            }
        }
    }

    // button settings
    const disableActions = rebate === initialRebate || isSaving || disabled;
    function onCancel() { setRebate(initialRebate) }

    async function submitForm() {
        setIsSaving(true);
        try {
            await updateRebate(rebate / 100.0);
            setSaveError(null)
        } catch (err) {
            setSaveError(err.message);
        } finally {
            setIsSaving(false);
        }
    }


    return (
        <Card
          sectioned
          title="Store-Wide Cashback Percentage"
          >
            <Form onSubmit={submitForm}>
                <Stack spacing="loose" vertical>
                    <FormLayout>
                        <div id="rebate-row">
                            <div id="rebate-field-wrapper">
                                <TextField
                                    type="number"
                                    align="center"
                                    suffix="%"
                                    value={String(rebate)}
                                    onChange={updateValue}
                                    disabled={isSaving || disabled}
                                    onBlur={() => { if (isNaN(Number(rebate))) { setRebate(initialRebate) }}}
                                />
                            </div>
                            <p>
                                A $100 item will offer a ${String(rebate)} rebate in exchange for an Instagram story feature.
                            </p>
                        </div>
                        <RangeSlider
                            value={rebate}
                            output={true}
                            onChange={updateValue}
                            disabled={isSaving || disabled}
                        />
                    </FormLayout>
                    <Stack distribution="trailing" alignment="center">
                        <InlineError message={saveError} />
                        <ButtonGroup>
                            <Button plain onClick={onCancel} disabled={disableActions}>Cancel</Button>
                            <Button primary submit disabled={disableActions} loading={isSaving}>Save</Button>
                        </ButtonGroup>
                    </Stack>
                </Stack>
            </Form>
        </Card>
    )
}

RebateSetter.propTypes = {
    rebate: PropTypes.number.isRequired,
    updateRebate: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
}

export default RebateSetter;