import React, {useState, useEffect, useCallback, useContext} from 'react';
import { FooterHelp, Link as PolarisLink, ChoiceList,TextContainer, Page, Card, TextField,SettingToggle, Spinner, Layout } from '@shopify/polaris';
import {Context as ShopifyAppBridgeContext, TitleBar} from '@shopify/app-bridge-react'

import { Redirect as ShopifyRedirect } from '@shopify/app-bridge/actions';

import RebateSetter from './components/RebateSetter/RebateSetter';
import { DefaultErrorBoundary } from '../services/bugsnag'
import AccountSettings from './AccountSettings'
import AccountSignUp from './AccountSignUp'
import MaxRebate from './components/MaxRebate/MaxRebate';

function A1({session, updateSession}) {
  const [isReloading, setIsReloading] = useState(true);
  useEffect(() => {
    if (isReloading) {
      session.api.GetAttachedProfile().then((response) => {
        const profile = response.data.message;
        setIsReloading(false);
        updateSession(session.updating({user: profile}));
      }).catch((err) => {
        console.error(err);
        setIsReloading(false);
        updateSession(session.updating({user: null}));
      });
    }
  }, [isReloading, updateSession, session.api, session]);
  if (isReloading) {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
        <Spinner />
      </div>
    );
  } else {
    let accountPage;
    if (session.user) {
      accountPage = <AccountPage session={session} updateSession={updateSession}/>;
    } else {
      accountPage = <SignUpPage session={session} updateSession={updateSession}/>;
    }
    return (
      <>
        {accountPage}
      </>
    )
  }
}

function SignUpPage({session, updateSession}) {

  return (
    <Page>
      <TitleBar
        title="Account"
      />
      <Layout>
        <AccountSignUp email={session.shop.email} api={session.api} shop_id={session.shop._id} updateSession={updateSession} />
      </Layout>
      {notConnectedFooter}
    </Page>
  );
}

function AccountPage({session, updateSession}) {
  const app = useContext(ShopifyAppBridgeContext)

  useEffect(() => {
    ShopifyRedirect.create(app).dispatch(ShopifyRedirect.Action.REMOTE, 'https://brand.guru.club');
  },[])
  const [isActivating, setIsActivating] = useState(false);
  const [autoTip, setAutoTip] = useState(session.user.autoTip)
  const [scriptTagPages, setScriptTagPages] = useState(session.user.scriptTagPages)
  const [updatingTip, setUpdatingTip] = useState(false)
  const handleTipChange = useCallback((newValue) => {newValue = Number(newValue); if (!isNaN(newValue) && newValue < 100 && newValue >= 0) { setAutoTip(newValue * 100) }}, []);

  const accountSettingsDiv = (
      <DefaultErrorBoundary>
        <AccountSettings email={session.user.email} handle={session.user.minstagram.username} updateSession={updateSession} session={session}/>
      </DefaultErrorBoundary>
  );

  let scriptTagMessage;
  if (!session.user.active) {
    scriptTagMessage = "Plugin script tags for your store are disabled.";
  } else {
    scriptTagMessage = "Plugin script tags are enabled on your store.";
  }

  const updateAutoTip = async function(newValue) {
    try {
      setUpdatingTip(true)
      const update = {autoTip: newValue}
      await session.api.UpdateProfile(update);
      const newSession = await session.updatingUser(update)
      updateSession(newSession);
    } catch (err) { } finally {
      setUpdatingTip(false)
    }
  }

  const updateRebate = async function(newValue) {
    const update = {discount: newValue}
    await session.api.UpdateProfile(update);
    const newSession = await session.updatingUser(update)
    updateSession(newSession);
  }

  const updateMaxRebate = async function(newValue) {
    if (!Number.isInteger(newValue)) {
      throw new Error("Please enter a valid dollar amount.")
    }
    const update = { maxRebate: newValue }
    await session.api.UpdateProfile(update);
    const newSession = await session.updatingUser(update);
    updateSession(newSession);
  }

  const [pluginError, setPluginError] = useState(null)
  const pluginDisabled = !session.user || !session.user.active;
  const profileEditCard = (session.connected || null) && (
    <>
      <Layout.AnnotatedSection
        title="Shop Settings"
        description="This is how your rebates will be presented to shoppers. Set a store-wide rebate percentage, which is the percent of an item's total price to be returned to a user for a unique posted review of that item."
      >
        <DefaultErrorBoundary>
        <Card
          sectioned
          title="Plugin Status"
          >
          <SettingToggle
              action={{
                content: session.user.active ? 'Disable Plugin' : 'Enable Plugin',
                onAction: async () => {
                  setPluginError(null)
                  const active = !session.user.active;
                  setIsActivating(true);
                  try {
                    const newSession = await session.withActive(active, scriptTagPages);
                    updateSession(newSession);
                  } catch (err) {
                    if (err.message === "UsageChargeError") {
                      setPluginError("Please reload the app and accept usage charges in order to enable plugin.")
                    }
                  } finally {
                    setIsActivating(false);
                  }
                },
                loading: isActivating,
              }}
              >
              {scriptTagMessage}
            </SettingToggle>
            {pluginError}
        </Card>
        </DefaultErrorBoundary>
        <DefaultErrorBoundary>
          <Card sectioned title="Promo Locations">
            <ChoiceList
              allowMultiple
              disabled={pluginDisabled || isActivating}
              title="The widget will display on the order status page. You can display it on the product and cart pages as well. Display on "
              choices={[{
                "label": "Product Page",
                "value": "product",
                disabled: pluginDisabled
              }, {
                "label": "Cart Page",
                "value": "cart",
                disabled: pluginDisabled
              }, {
                "label": "Order Confirmation Page",
                "value": "order",
                disabled: true
              }]}
              selected={session.user.active?scriptTagPages:[]}
              onChange={async selected=>{
                const retain = scriptTagPages
                setScriptTagPages(selected)
                if (session.user.active) {
                  setIsActivating(true);
                  try {
                    const newSession = await session.withActive(true, selected);
                    updateSession(newSession);
                  } catch (err) { setScriptTagPages(retain) } finally {
                    setIsActivating(false);
                  }
                }
              }}
            />
          </Card>
        </ DefaultErrorBoundary>
        <DefaultErrorBoundary>
          <RebateSetter
            rebate={session.user.discount}
            updateRebate={updateRebate}
            disabled={pluginDisabled}
            />
        </DefaultErrorBoundary>
        <DefaultErrorBoundary>
          <MaxRebate
              disabled={pluginDisabled}
              maxRebate={session.user.maxRebate}
              updateMaxRebate={updateMaxRebate}
          />
        </DefaultErrorBoundary>
        <DefaultErrorBoundary>
          <Card
            sectioned
            title="Auto Tip"
            secondaryFooterActions={[{
              content: 'Cancel',
              onAction: ()=>{setAutoTip(session.user.autoTip || 0)},
              disabled: session.user.autoTip == autoTip || updatingTip || pluginDisabled
            }]}
            primaryFooterAction={{
              content: 'Save',
              onAction: ()=>{updateAutoTip(autoTip)},
              loading: updatingTip,
              disabled: session.user.autoTip == autoTip || pluginDisabled
            }}
            >
            <TextContainer>
              You can tip your customer automatically upon successfully reviewing your item. The tip is delivered as an added cashback offering on the customer's following cashback deal.
            </TextContainer>
            <div style={{width: '100px', marginTop: '10px'}}>
              <TextField disabled={pluginDisabled} type="number" prefix="$" min={0} max={100} value={(autoTip / 100).toFixed()} onChange={handleTipChange}/>
            </div>
          </Card>
        </DefaultErrorBoundary>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
			  title="Promote Guru Club"
				description="Explore our options for educating your shoppers about Guru Club."
			>
				<Card sectioned>
					<PolarisLink external url='https://guru.club/brand-comarketing-resources/'>
            Promote Guru Club to your shoppers.
          </PolarisLink>
				</Card>

      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
			  title="Co-Branded Ad Campaign"
				description="Let us help pay for your Instagram ads."
			>
				<Card sectioned>
					<PolarisLink external url='https://guru.club/co-branding/'>
            Launch a co-branded ad campaign.
          </PolarisLink>
				</Card>

      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title="Commission"
        description="Description of how and when you will be charged.">
        <Card sectioned>
          <TextContainer>
            <p>
            After your shopper posts the Instagram story, Guru Club charges you 5% of the original product price. (Maximum $10)
            </p>
            <p>
              Charges to use this app are delivered via Shopify.
            </p>
            <p>
            Learn more about{' '}
            <PolarisLink external url="https://guru.club/for-brands/brand-support/#pricing">
              guru.club commission.
            </PolarisLink>
            </p>
          </TextContainer>
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title="Terms & Conditions"
      >
        <Card sectioned>
          <p>
            Read the guru.club {' '}<PolarisLink external url='https://guru.club/terms-of-service-brands/'>
              terms and conditions.
              </PolarisLink>
          </p>
        </Card>
      </Layout.AnnotatedSection>
    </>
  )

  let footer = defaultFooter;

  return (
    <Page>
      <TitleBar
        title="Account"
      />
      Account
      <Layout>
        {accountSettingsDiv}
        {profileEditCard}
      </Layout>
      {footer}
    </Page>
  );
}


const notConnectedFooter = (
  <FooterHelp>
    Learn how to {' '}
    <PolarisLink external url='https://guru.club/for-brands/brand-support/#getting-started'>
      get started.
    </PolarisLink>
  </FooterHelp>
);

const defaultFooter = (
  <FooterHelp>
    Learn more about {' '}
    <PolarisLink external url={'https://guru.club/for-brands/brand-support/#pricing'}>
      guru.club pricing.
    </PolarisLink>
  </FooterHelp>
)

export default A1;
