import React, { Component } from 'react';
import casestudies from './data/case_studies.js';
import heart from './Images/icons8-heart-outline-24.png';
import crowd from './Images/icons8-crowd-24.png';
import shopify_screenshot from './Images/plugin_screenshot.png';
import shopify_logo from './Images/shopify_logo_whitebg.svg';
import inverted_shopify_logo from './Images/shopify_logo_darkbg.svg';
import './BrandAbout.css';

class BrandAbout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      study: casestudies[0],
      case_study_images: [0,1,2]
    };
    this.case_study_section = React.createRef();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const { clientWidth } = document.body;
    var case_study_images;
    if (clientWidth < 675) {
      case_study_images = [1];
    } else if (clientWidth < 1013) {
      case_study_images = [0, 1];
    } else {
      case_study_images = [0, 1, 2];
    }
    if (case_study_images.length !== this.state.case_study_images.length) {
      this.setState({case_study_images: case_study_images});
    }
  }

  render() {
    const transitionMod = this.state.transitioning ? 'hide' : 'show';
    const transitionClass = `cs-tran ${transitionMod}`;

    var reviewBubbles = displayedReviews.map((review) => {
      return <BrandReview key={review.name}
        src={'http://conduit-dev-website.s3.amazonaws.com/' + review.name}
        width={review.width}
        height={review.height} />
    });

    return (
      <div className="room-up-top">
        <div className="about-div column centered dark-to-blue align-center">
            <div className="column centered align-center" style={{padding: '100px'}}>
              <p style={{maxWidth: '500px'}}>Add the Sales Channel to your store on the Shopify App Store.</p>
              <div className="row centered align-center flex-wrap">
                <img alt="Shopify" id="shopify-logo" src={inverted_shopify_logo} style={{margin: '10px'}}/>
                <a style={{margin: '10px'}} id="main_download_link" href={"https://apps.shopify.com/guru-club"}>Add app</a>
              </div>
            </div>
            <div className="row flex-wrap centered" style={{maxWidth: '1500px', alignItems: 'center'}}>
              {reviewBubbles}
            </div>
            <div id="discover-text" className="align-center">
              <h1>Incentivize shoppers to share your brand on Instagram</h1>
              <h2>Marketing talent is hidden among your target consumers.</h2>
              <div className="align-center row align-start flex-wrap centered" style={{margin: '50px 0 80px 0', fontSize: '22px', lineHeight: '2em'}}>
                  <div className="brand-div">
                    <p><h5>Turn-key marketing solution</h5></p>
                    <p>Simply apply, add your products, and set rebates to reward shoppers for tagging and @mentioning your brand on Instagram.</p>
                  </div>
                  <div className="brand-div">
                    <p><h5>Cost-efficient advertising</h5></p>
                    <p>Turn your marketing from a cost center to a profit center. All you need is inventory to get started.</p>
                  </div>
                  <div className="brand-div">
                    <p><h5>Peer influence & content</h5></p>
                    <p>Seed products with customers who are active Instagram users and create engaging content.</p>
                  </div>
              </div>
            </div>
            <div id="brand-video-container">
              <iframe id="brand-video" width="560" height="315" src="https://www.youtube.com/embed/EHy2LOajjfo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}>
              </iframe>
            </div>
        </div>
        <div className="case-study about-div column white centered align-center">
          <h1 style={{fontWeight: '400'}}>case studies</h1>
          <p style={{marginTop: '0'}}>Data collected and delivered for each product sold.</p>
          <div className="row align-center">
            {casestudies.map((study) => {
              const selected = study.company === this.state.study.company;
              return (
                <CaseStudyCompany lq={study.lq_image} hq={study.hq_image}
                  onClick={e => this.openCaseStudy(study)} selected={selected}
                  key={study.company} company={study.company}/>
              );
            })}
          </div>
          <div className={'row centered align-center ' + transitionClass + ' flex-wrap'}
            style={{maxWidth: '100%', margin: '50px 0 50px 0'}}>
            <DatumDisplay value={this.state.study.sold} label="Products Sold"
              formatter={(value) => {
                return Math.floor(value);
              }}/>
            <DatumDisplay value={this.state.study.new_content} label="New Content Owned"
              formatter={(value) => {
                return Math.floor(value);
              }}/>
            <DatumDisplay value={this.state.study.reach} label="Reach"
              formatter={(value) => {
                var thousands = Math.floor(value / 1000);
                var hundreds = Math.floor((value % 1000) / 100);
                return thousands + '.' + hundreds + 'K';
              }}/>
            <DatumDisplay value={this.state.study.engagement} label="Engagement"
              formatter={(value) => {
                var raw;
                var rate = (100 * value / this.state.study.reach).toFixed(1);
                if (value < 1000) raw = Math.floor(value);
                else {
                  var thousands = Math.floor(value / 1000);
                  var hundreds = Math.floor(value % 1000);
                  raw = thousands + ',' + hundreds;
                }
                return raw + ' (' + rate + '%)';
              }}/>
              <DatumDisplay value={this.state.study.cost} label="Cost"
                formatter={(value) => {
                  var dollars = Math.floor(value / 100);
                  var pennies = ('0' + Math.floor(value % 100)).slice(-2);
                  return '$' + dollars + '.' + pennies;
                }}/>
              <DatumDisplay value={this.state.study.average_discount} label="Avg. Rebate"
                formatter={(value) => {
                  return Math.floor(value) + '%';
                }}/>
          </div>
          <div className="testimonial column flex-start align-center">
            <p className={'quote ' + transitionClass}>"{this.state.study.testimonial}"</p>
            <p className={transitionClass} style={{textAlign: 'center'}}>- {this.state.study.referrer}</p>
          </div>
          <div className="row space-around" ref={this.case_study_section} style={{height: '365px', margin: '30px 0 0 0'}}>
            {this.state.case_study_images.map((index) => {
              const casestudy = this.state.study.posts[index];
              const {url, handle, bucket_key, likes, followers } = casestudy;
              const imageSrc = `https://dt8pdwz1r5mq4.cloudfront.net/${bucket_key}`;
            return (
              <CaseStudyProp likes={likes} followers={followers}
                key={index} handle={handle} url={url} imageSrc={imageSrc}
                transitionClass={transitionClass} />)
          })}
          </div>
        </div>
        <div className="about-div white-to-blue column centered align-center">
          <div id="shopify-block" className="centered align-center stretch-width">
            <div id="integrate-block" className="align-center centered">
              <h1 id="integrates-with-shopify">easily integrate your store</h1>
              <img alt="Shopify" id="shopify-logo" src={shopify_logo} />
            </div>
            <video id="plugin-screenshot" poster={shopify_screenshot} autoPlay={this.props.isDesktop} playsInline={true} muted={true} loop>
              <source src='https://dt8pdwz1r5mq4.cloudfront.net/shopify.mp4' type="video/mp4" />
            </video>
          </div>
              <a href={"https://apps.shopify.com/guru-club"} style={{marginTop: '20px'}}>Add now on the app store.</a>
        </div>
        <div className="about-div blue-to-dark column centered align-center mid-height">
        </div>
      </div>
    )
  }

  openCaseStudy = (study) => {
    this.setState({transitioning: true});
    setTimeout(() => {
      this.setState({study: study, transitioning: false});
    }, 200);
  }
}

class CaseStudyCompany extends Component {
  state = {hovering: false};

  render() {
    const showColor = this.state.hovering || this.props.selected;
    const enlarge = this.state.hovering && !this.props.selected;
    const imageSrc = showColor ? this.props.hq : this.props.lq;
    var boxShadow = 'none';
    if (this.props.selected) boxShadow = '0px 0px 2px 2px rgba(0,0,0,0.25)';
    else if (enlarge) boxShadow = '0px 0px 4px 4px rgba(0,0,0,0.25)';
    return (
      <img alt={'Case study for ' + this.props.company} className="case-study-company" onMouseEnter={this.hover}
        onMouseLeave={this.escape} onClick={this.props.onClick} src={imageSrc}
        style={{boxShadow: boxShadow}} />
    )
  }

  escape = (e) => {
    this.setState({hovering: false});
  }

  hover = (e) => {
    this.setState({hovering: true});
  }
}

class DatumDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {value: 0};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.resetTimer();
    }
  }

  componentDidMount() {
    this.resetTimer();
  }

  resetTimer = () => {
    clearInterval(this.interval);
    this.setState({value: 0});
    const step = this.props.value / 30;
    this.interval = setInterval(() => {
      var { value } = this.state;
      value += step;
      if (value < this.props.value) {
        this.setState({value: value});
      } else {
        this.setState({value: this.props.value});
        clearInterval(this.interval);
      }
    }, 20)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="column space-between align-center datum-display">
        <p style={{fontWeight: '500', fontSize: '26px', color: '#0E83B1'}}>{this.props.formatter(this.state.value)}</p>
        <p style={{fontSize: '16px'}}>{this.props.label}</p>
      </div>
    );
  }
}

class CaseStudyProp extends Component {
  state = {imageClass: "dormant"};

  render() {
    var handle;
    var engagement;
    if (this.state.imageClass === 'active') {
      handle = (
        <div className="text-div" style={{justifyContent: 'flex-start'}}>
          <p>{this.props.handle}</p>
        </div>
      )
      engagement = (
        <div className="text-div" style={{justifyContent: 'space-between'}}>
          <div className="row align-center">
            <img src={heart} alt="Likes"/>
            <p style={{marginLeft: '10px'}}>{this.props.likes}</p>
          </div>
          <div className="row align-center">
            <p style={{marginRight: '10px'}}>{this.props.followers}</p>
            <img src={crowd} alt="Followers"/>
          </div>
        </div>
      )
    }
    return (
      <div className={'case-study-prop ' + this.state.imageClass + ' ' + this.props.transitionClass}>
        {handle}
        <img alt="" onClick={this.becomeActive} onMouseEnter={this.becomeActive}
          onMouseLeave={this.becomeDormant} className={this.state.imageClass}
          src={this.props.imageSrc} />
        {engagement}
      </div>
    )
  }

  openExternalLink = () => {
    var win = window.open(this.props.url, '_blank');
    win.focus();
  }

  becomeActive = (e) => {
    e.target.onClick = this.openExternalLink;
    this.setState({imageClass: 'active'});
  }

  becomeDormant = (e) => {
    e.target.onClick = this.becomeActive;
    this.setState({imageClass: 'dormant'});
  }
}

const displayedReviews = [
  { 
    name: 'review1.png',
    width: '386px',
    height: '219px'
  },
  { 
    name: 'review2.png',
    width: '388px',
    height: '176px'
  },
  { 
    name: 'review3.png',
    width: '390px',
    height: '182px'
  },
  { 
    name: 'review4.png',
    width: '379px',
    height: '184px'
  },
  { 
    name: 'review5.png',
    width: '388px',
    height: '299px'
  },
  { 
    name: 'review6.png',
    width: '388px',
    height: '222px'
  }
]

class BrandReview extends React.Component {

  state = {};

  onload = () => {
    this.setState({show: true});
  }

  render() {
    var className = "review";
    if (this.state.show) {
      className += " show";
    }

    var style = {width: this.props.width, height: this.props.height};

    return (
      <img src={this.props.src} className={className} onLoad={this.onload} style={style} />
    );
  }

}

export default BrandAbout;
