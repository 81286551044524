import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import './Brand.css';
import './Landing.css';
import './Influencer.css';
import './Signup.css';
import './Apply.css';
import './Burger.css';
import './BulletinBoard.css';
import './SectionIntro.css';
import './Portal.css';
import './ContactUs.css';
import './EmbeddedInstagram.css';
import Shopify from './Shopify/index';
import Influencer from './Influencer';
import ResetPass from './reset_pass';
import ForgotPass from './forgot_pass';
import Login from './Login';
import Admin from './admin/index';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { unregister } from './registerServiceWorker';
import { Session } from './Shopify/session.js';
import '@shopify/polaris/styles.css';
import Support from './Shopify/Support';
import { DefaultErrorBoundary } from './services/bugsnag'

function TopLevel() {
  const [session, login] = useState(new Session());
  
  return (
    <div>
      <Switch>
        <Route path="/app/shopify/support">
          <Support />
        </Route>
        <Route path="/app/shopify">
          <Shopify session={session} login={login} />
        </Route>
        <Route path="/app/reset_pass">
          <ResetPass />
        </Route>
        <Route path="/app/forgot_pass">
          <ForgotPass />
        </Route>
        <Route path="/app/login">
          <Login />
        </Route>
        <Route path="/app/admin">
          <Admin />
        </Route>
        <Route path="/app/">
          <Influencer />
        </Route>
      </Switch>
    </div>
  );
}

ReactDOM.render(
  <DefaultErrorBoundary>
    <Router>
      <TopLevel />
    </Router>
  </DefaultErrorBoundary>,
  document.getElementById('root'));
//registerServiceWorker();
unregister();
