import seaport from '../Images/seaport.jpeg';
import heychick from '../Images/hey_chick.png';
import heychick_gray from '../Images/hey_chick_no_saturation.png';

export default [
  {
    company: 'Hey Chick',
    lq_image: heychick_gray,
    hq_image: heychick,
    sold: 15,
    new_content: 30,
    reach: 55700,
    engagement: 7126,
    cost: 9232,
    average_discount: 50,
    posts: [
      {
        bucket_key: "hawk.jpg",
        url: "https://www.instagram.com/p/BtZDHBhl-XM/",
        handle: "sydney_hawk",
        likes: "902",
        followers: "6,746"
      },
      {
        bucket_key: "kenziekathrynn.jpg",
        url: "https://www.instagram.com/p/BuFXNcyg06L/",
        handle: "kenziekathrynn",
        likes: "588",
        followers: "3,053"
      },
      {
        bucket_key: "victoriaoliver.jpg",
        url: "https://www.instagram.com/p/BtjNLFYH1xe/",
        handle: "victoriaoliver",
        likes: "654",
        followers: "8,899"
      }
    ],
    testimonial: "Choosing the correct form of marketing to reach millennials hasn’t been easy. Instagram ads are intrusive, paid influencer ads seem fake and if you gift items to influencers you might never get a post. guru.club is the easiest, least expensive way to reach millennials and you get great content for your Instagram too!",
    referrer: "Michelle Gilbert, Hey Chick"
  },
  {
    company: 'Boston Seaport',
    lq_image: seaport,
    hq_image: seaport,
    sold: 13,
    new_content: 26,
    reach: 64900,
    engagement: 8177,
    cost: 5026,
    average_discount: 75,
    posts: [
      {
        bucket_key: "pditullio_square.jpg",
        url: "https://www.instagram.com/p/BuB-sUnFF1_/",
        handle: "pditullio",
        likes: "334",
        followers: "1,489"
      },
      {
        bucket_key: "krista1470_square.jpg",
        url: "https://www.instagram.com/p/BsTPOecggYe/",
        handle: "krista1470",
        likes: "453",
        followers: "2,296"
      },
      {
        bucket_key: "jimmy_seaport_square.jpg",
        url: "https://www.instagram.com/p/Bpz15HfF593/",
        handle: "jimmybuckets3",
        likes: "310",
        followers: "2,168"
      }
    ],
    testimonial: "Partnering with the guru team as a brand has allowed us to reach an audience we had not previously tapped into. We look forward to leveraging this relationship in the future.",
    referrer: "Katie Lorrey, Boston Seaport"
  }
]
