import { categories } from './Constants.js';

const Validators = {
  email: /^.+@[a-z0-9-.]+\.[a-z]{2,67}$/i,
  password: /.{8,64}/,
  instagram: /^@?[A-Z0-9_\\.]+$/i,
  exists: /.+/,
  category: new RegExp(`^(${categories.join('|')})$`),
  phone: /^(\d|\.|\+|\(|\)|\s|-|x){10,}$/
};

export default Validators;
