function SessionInventory(api) {
  this.api = api;
}

SessionInventory.prototype.loadDeals = async function() {
  // if something sees that deals is set, it might try to access toTip
  // which is undefined without the Object.assign
  this.deals       = Object.assign({toTip: []}, await this.api.MyDeals());
  this.deals.toTip = await this.api.GetTips();
  return this.deals;
}

SessionInventory.prototype.allProducts = async function(reload = false) {
  if (this.storedProducts && !reload) {
    return Object.values(this.storedProducts);
  } else
  {
    let allProducts = await this.api.MyProducts();
    allProducts = allProducts.reduce(function(object, product) {
        object[product._id] = Object.assign({done: [], current: [], sold: 0}, product);
        return object;
      }, {});

    const deals = await this.loadDeals();

    const attachDeals = function (type) {
      deals[type].forEach(function(deal) {
        if (deal.product in allProducts)
        {
          allProducts[deal.product][type].push(deal);
        }
      });
    };
    attachDeals('done', deals, allProducts);
    attachDeals('current', deals, allProducts);
    this.storedProducts = allProducts;
    return Object.values(allProducts);
  }
}

SessionInventory.prototype.getProduct = async function(id) {
  const product = await this.api.GetProduct(id);
  if (this.storedProducts)
  {
    const prevValue = this.storedProducts[product._id] || {};
    this.storedProducts[product._id] = Object.assign(prevValue, product);
  }
  return product;
}

SessionInventory.prototype.getDeal = async function(id) {
  const deal = await this.api.GetDeal(id);
  if (this.storedProducts)
  {
    const prevValue = this.storedProducts[deal.product];
    if (prevValue)
    {
      const wrongArray = deal.status === 'Done' ? 'current' : 'done';
      const rightArray = deal.status === 'Done' ? 'done' : 'current';
      const removeIdx = prevValue[wrongArray].findIndex(wrongDeal => wrongDeal._id === deal._id);
      if (removeIdx) {
        prevValue[wrongArray].splice(removeIdx);
      }
      var storedIdx = prevValue[rightArray].findIndex(rightDeal => rightDeal._id === deal._id);
      if (storedIdx) {
        prevValue[rightArray][storedIdx] = deal;
      } else {
        prevValue[rightArray].push(deal);
      }
    }
    this.storedProducts[deal.product] = prevValue;
  }
}

SessionInventory.prototype.updateProduct = async function(updated) {
  await this.api.UpdateProduct(updated)

  if (this.storedProducts)
  {
    this.storedProducts[updated._id] = Object.assign(this.storedProducts[updated._id] || {}, updated);
  }
}

export default SessionInventory;
