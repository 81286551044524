import React from 'react';
import { AppProvider, Page, Heading, Subheading, TextContainer, Layout, List } from '@shopify/polaris';

class Support extends React.Component {
  render() {
    return (
      <AppProvider>
        <div style={{color: "white"}}>
          <Page title="Support Documentation">
            <Layout>
              <Layout.Section>
                <p>
                This page is meant to guide you through our Shopify sales channel. For direct assistance, please contact <a href="mailto:shop@guru.club?subject=Support">shop@guru.club</a>.
                </p>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                  <div id="how-it-works"><Heading>How it Works</Heading></div>
                  <p>
                    guru.club operates as a sales channel in your Shopify Admin console. Through us you list products for sale on discount. All products sold on our platform imply a deal with the customer in which the customer promises to post a review of your product. In this post, your product will be the focus, and your Instagram page will be tagged and/or mentioned.
                  </p>
                  <p>
                    As part of your deal with the customer, the content created and shared will be yours to use. This content can be downloaded from the sales channel app.
                  </p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                  <div id="pricing"><Heading>Pricing</Heading></div>
                  <p>
                    For each sale, guru.club will issue a charge to you equaling 5% of the original price.
                  </p>
                  <p>
                    This model implies that a larger discount on a product (selling it for less money to the customer) will result in a smaller charge for you. A smaller discount (thus charging the customer more) will result in a bigger charge for you.
                  </p>
                  <p>
                  Any time a customer fails to share a post in the valid timeframe, guru.club grants you 85% of the product’s “lost” value (original price minus exclusive price) as credit towards the guru.club app. For example using the above scenario, imagine that a customer fails to post the product. You will still owe us $3.00, but your account will be credited $68.00. You will forego the $3.00 charge, and every subsequent charge, until the $68.00 worth of credits are used up.

                  </p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                  <div id="how-much-should-i-discount"><Heading>How Much Should I Discount?</Heading></div>
                  <p>
                    Obviously, the more you discount your item, the more attractive it will look to customers. Keep in mind that this <b>does not mean</b> that a larger discount should indicate that you will be taken advantage of.
                  </p>
                  <p>
                    What's important to understand is that shoppers on guru.club (a.k.a. Gurus) don't pay just the discounted price at checkout. The discount is rewarded as a rebate, after the Guru creates their post mentioning your brand. If our platform finds that the Guru has failed to review your product and brand in a satisfactory way, they will forfeit their rebate. In this way, a larger discount implies more at stake for the Guru.
                  </p>
                  <p>
                    A larger discount thus encourages the Guru to take their review of your brand more seriously, because of the large amount they risk forfeiting.
                  </p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                <div id="getting-started"><Heading>Getting Started</Heading></div>
                <Subheading>Creating an Account</Subheading>
                <p>
                Your account is created after you hit Connect on the Account page and then fill out the form for creating an account. After completing the form, hitting Apply should close the form and bring you back to the Account page. If the Account page still says Connect, you may need to reload the page.
                </p>
                <div id="application-process"><Subheading>Application Process</Subheading></div>
                <p>
                After creating an account, the details you filled out in the form are sent to our team for review. Upon review, you should receive an email notification updating you on your status. Your status is reflected on the sales channel as a banner at the top of every page.
                </p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                <div id="publishing"><Heading>Publishing Your Store</Heading></div>
                <p>
                Once accepted, your store remains unpublished until you are ready to publish it. In order to publish, you will need:
                </p>
                <p>
                  <List type="bullet">
                    <List.Item>A unique display name</List.Item>
                    <List.Item>A shop logo</List.Item>
                    <List.Item>A shop banner</List.Item>
                  </List>
                </p>
                <p>
                The logo should be a square image, featuring the name of your store.
                </p>
                <p>
                The banner should be a landscape image of whatever you like.
                </p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                <div id="listing-products"><Heading>Listing Your Products</Heading></div>
                <p>
                Listing products is done in your Shopify Admin Products page, outside of the guru.club sales channel. Select the correct products, then under Actions choose Make products available.  Check off guru.club.
                </p>
                <Subheading>Product Discount</Subheading>
                <p>
                By default, any product you list will be discounted 50%. To edit any product’s discount, head to the Products page in the guru.club sales channel. There you may edit the final price of the product to a guru.
                </p>
                <Subheading>Product has errors</Subheading>
                <p>
                Errors are most commonly caused by 2 issues:
                </p>
                <p>
                  <List type="bullet">
                    <List.Item>Product has no image</List.Item>
                    <List.Item>Product has too many options</List.Item>
                  </List>
                </p>
                <p>
                A product, or at least one variant of the product, needs an image to be listed on guru.club. Images are added in your Shopify Admin Products page, outside of guru.club.
                </p>
                <p>
                guru.club supports products which have no options, one option, or two options if one of them is “Color”. This is due to constraints on the guru.club UI.
                </p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section>
                <TextContainer>
                <div id="ugc"><Heading>User Generated Content</Heading></div>
                <p>
                  Your content will populate the Sales page in the sales channel as they are created. By default, we require users to post their reviews 30 days after purchasing the item. This can change, e.g. if a user tells us that the product took longer than that to ship. Posts will be visible to you 3 days after the user shares them. From there, you will be able to view and download content for re-use.
                </p>
                <div id="tips">
                  <Subheading>Tips</Subheading>
                  <p>
                    For recently completed posts, you will have the option to tip your customer. Tipping grants in-store credit only for sales within the guru.club app. The customer will be discounted the amount that you tip on any future purchase.
                  </p>
                </div>
                </TextContainer>
              </Layout.Section>
            </Layout>
          </Page>
        </div>
      </AppProvider>
    )
  }
}

export default Support;
