import React, { useState } from 'react';
import { bugsnagClient } from '../services/bugsnag'
import {  Link as PolarisLink, Card, TextField, Layout } from '@shopify/polaris';
import Validators from '../helpers/Validators'

function AccountSignUp({ email, api, shop_id, updateSession }) {

    const [inputEmail, setInputEmail] = useState(email || '')
    const [handle, setHandle] = useState('')
    const [emailError, setEmailError] = useState(null)
    const [handleError, setHandleError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <Layout.AnnotatedSection
            title="Finish Sign Up"
            description="In order to finish the sign up process, we need a unique email address by which we can reach you, and an Instagram handle to attach to your account."
        >
            <Card
                sectioned
                title="Account Info"
                primaryFooterAction={{
                    content: 'Submit',
                    loading: isLoading,
                    onAction: ()=>{
                        setHandleError(null); setEmailError(null);
                        if (!Validators.email.test(inputEmail)) {
                            setEmailError("Please provide a valid email.")
                        } else if (!Validators.instagram.test(handle)) {
                            setHandleError("Please provide a valid instagram handle.")
                        } else {
                            setIsLoading(true)
                            api.CreateAccount(inputEmail, handle, shop_id).then(response => {
                                const { data: { message: { profile } } } = response
                                updateSession({user: profile})
                            }).catch(err => {
                                switch (err.message) {
                                    case "EmailAlreadyExistsError":
                                        setEmailError("This address is already in use.")
                                        break;
                                    
                                    case "DuplicateInstagramHandle":
                                        setHandleError("This handle is already in use.")
                                        break;
                                        
                                    default:
                                        bugsnagClient.notify(err)
                                        setHandleError("An unexpected error occurred. Please make sure you entered the correct Instagram handle.")
                                }
                            })
                            .finally(() => setIsLoading(false))
                        }
                    }
                }}
            >
                <p>
                By clicking <strong>Submit</strong>, you agree to accept guru.club's {' '}<PolarisLink external url='https://guru.club/terms-of-service-brands/'> terms and conditions</PolarisLink>.
                </p>
                <Card.Section
                    title="Email"
                >
                    <p style={{marginBottom: '10px'}}>The address by which we can reach you.</p>
                    <TextField type="email" error={emailError} value={inputEmail} onChange={setInputEmail} />
                </Card.Section>
                <Card.Section
                    title="Instagram Handle"
                >
                    <p style={{marginBottom: '10px'}}>After you enable the plugin, this will be the handle that your shoppers will tag in exchange for cashback rewards.</p>
                    <TextField type="text" error={handleError} pattern={Validators.instagram} prefix="@" value={handle} onChange={setHandle} />
                </Card.Section>
            </Card>
        </Layout.AnnotatedSection>
    )
}

export default AccountSignUp;