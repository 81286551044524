import React, { useState, useEffect } from 'react';
import './GalleryItem.css';
import { Avatar, Button, Stack, TextStyle, InlineError } from '@shopify/polaris';

const profilePictureBucket = "https://dr2ucvwr1tnfi.cloudfront.net/";
const contentBucket = "https://dl0dpcradwvbv.cloudfront.net/";

function GalleryItem({deal, updateTip}) {
    const {tip: propTip, brand, influencer, content, story, tipsToDate, price} = deal;
    const [tip, setTip] = useState(propTip || 0);
    const [updatingTip, setUpdatingTip] = useState(false);
    const [tipError, setTipError] = useState(null);

    // using this to enable selective button loading
    const [settingTipValue, setSettingTipValue] = useState(null)
    
    async function _updateTip(amount) {
        try {
            setSettingTipValue(amount);
            setUpdatingTip(true);
            await updateTip(amount);
            setTip(amount);
        } catch (err) {
            setTipError(err.message);
        } finally {
            setSettingTipValue(null);
            setUpdatingTip(false);
        }
    }

    const { name, initials, handle } = extractName(influencer);

    let tipDiv;
    if (!tip && price) {
      tipDiv = (
          <Stack distribution="center">
            {[0.2, 0.33, 0.5].map(tipPercent => {
                const { label, value } = getRoundDollar(price, tipPercent)
                return (<Button
                            plain
                            loading={settingTipValue === value}
                            onClick={()=>_updateTip(value)}
                            disabled={updatingTip}
                            key={String(tipPercent)}
                        >{label}</Button>);
            })}
        </Stack>
        
      );
    } else if (tip) {
      tipDiv = `${penniesToString(tip)} tip delivered.`;
    }

    return (
        <div className="gallery-item">
            {contentAsDisplayable(content)}
            <div className="gallery-item-action-row">
                <Button plain download url={url(content)}>Download</Button>
            </div>
            <div className="info">
                <Stack vertical spacing="tight">
                    <TextStyle variation="strong">Tip @{handle}?</TextStyle>
                    <TextStyle variation="subdued">Tips to date {penniesToString(tipsToDate)}</TextStyle>
                </Stack>
                <Avatar
                    source={profilePictureBucket + influencer._id}
                    alt={"Deal with " + name}
                    initials={initials}
                    customer={true}
                    name={name}
                    size="large"
                />
            </div>
            {tipDiv}
            <InlineError message={tipError} />
        </div>
    )
}

const getRoundDollar = function(price, percentage) {
    const amount = Math.floor(Math.round(percentage * price / 100.0) * 100)
    percentage = Math.round(amount / price * 100);
    return {
        label: `$${(amount / 100).toFixed()} (${percentage}%)`,
        value: amount
    };
  }

function extractName(influencer) {
    const initials = influencer.firstName && influencer.lastName &&
    influencer.firstName.charAt(0) + influencer.lastName.charAt(0);
    let name;
    if (influencer.firstName && influencer.lastName) {
        name = influencer.firstName + " " + influencer.lastName;
    } else {
        name = influencer.igHandle;
    }
    return {
        name,
        initials,
        handle: influencer.igHandle
    }
}

function url(content) {
    if (content && content.length) {
        const toDisplay = content[content.length - 1];
        if (toDisplay.key) {
          return contentBucket + toDisplay.key;
        }
    }
}

function contentAsDisplayable(content) {
    if (content && content.length) {
        const toDisplay = content[content.length - 1];
        if (toDisplay.key) {
          const url = contentBucket + toDisplay.key;
          if (toDisplay.contentType === 'video') {
            return (<video controls src={url} />);
          } else {
            return (<img src={url} />);
          }
        }
    }
    return (<img />)//broken image is (in this case) better than nothing
}

function penniesToString(pennies) {
    return `$${(pennies / 100.0).toFixed(2)}`
  }

export default GalleryItem;