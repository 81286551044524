import React, { Component } from 'react';
import EmbeddedInstagram from './EmbeddedInstagram.js';
import download from './Images/download-dark.svg';
import './InfluencerAbout.css';
import embedHtml from './data/embed.js';

class InfluencerAbout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      headerClass: "intro-header",
      subheaderClass: "intro-subheader",
      closeTextClass: "intro-close-text"
    };
    setTimeout(() => {
      this.setState({headerClass: "intro-header appear"});
    }, 200);
    setTimeout(() => {
      this.setState({subheaderClass: "intro-subheader appear"});
    }, 400);
    setTimeout(() => {
      this.setState({closeTextClass: "intro-close-text appear"});
    }, 600);
    this.scrollToRef = React.createRef();
  }

  render() {
    return (
      <div className="room-up-top">
        <div className="about-div dark-to-blue column centered align-center" style={{minHeight: '400px'}}>
          <h1 style={{textAlign: 'center'}}>Shop, Share, Get Paid</h1>
          <ol className="interact" style={{listStylePosition: 'inside', textAlign: 'center', paddingInlineStart: '0px', lineHeight: '1.8em', lineSpacing: '2em'}}>
            <li>Sign up and see if you qualify as a guru.</li>
            <li>Browse and shop the trendiest and newest products.</li>
            <li>Earn cash back by creating a post or story on Instagram @mentioning the brand.</li>
          </ol>
          <a href="https://itunes.apple.com/us/app/guru-club/id1318477149?mt=8">
            <img alt="App Store" src={download} style={{height: "50px"}} />
          </a>
        </div>
        <div className="about-div white align-center row space-around flex-wrap">
          <EmbeddedInstagram rotate embedHtml={embedHtml} id="embedHtml" />
          <h1 style={{fontSize: '30px', width: '355.5px'}}>Earn store credit based on engagement and creativity.</h1>

        </div>
      </div>
    )
  }
}



export default InfluencerAbout
